import { useMemo } from 'react';
import _useMediaDevices from 'react-use/lib/useMediaDevices';
export function useMediaDevices() {
    var mediaDevices = _useMediaDevices();
    var _a = useMemo(function () {
        var _a, _b, _c;
        var devices = (_a = mediaDevices.devices) !== null && _a !== void 0 ? _a : null;
        var isVideoInputAvailable = (_c = (_b = mediaDevices.devices) === null || _b === void 0 ? void 0 : _b.some(function (device) { return device.kind === 'videoinput'; })) !== null && _c !== void 0 ? _c : null;
        return {
            devices: devices,
            isVideoInputAvailable: isVideoInputAvailable,
        };
    }, [mediaDevices]), devices = _a.devices, isVideoInputAvailable = _a.isVideoInputAvailable;
    var state = {
        devices: devices,
        isVideoInputAvailable: isVideoInputAvailable,
    };
    return [state];
}
