import { Stack, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { VideoList } from '@companyon/components';
import { Helmet } from 'react-helmet';

export function Help() {
  const { t } = useTranslation(['help']);
  return (
    <Stack
      maxWidth="5xl"
      alignSelf="center"
      marginX="auto"
      paddingX={{ base: 2, sm: 4, lg: 8 }}
      spacing={3}
    >
      <Helmet>
        <title>CompanyON: {t('heading')}</title>
      </Helmet>
      <Heading
        as="h1"
        color="brand.700"
        textAlign="left"
        fontSize={{ base: '5xl', md: '6xl' }}
      >
        {t('heading')}
      </Heading>
      <Heading
        as="h2"
        color="brand.400"
        textAlign="left"
        fontSize={'3xl'}
        paddingTop={12}
      >
        {t('customer.title')}
      </Heading>
      <Text fontSize="2xl">{t('videoHeader')}</Text>
      <VideoList template="help" />
    </Stack>
  );
}
