import { gql } from '@apollo/client';
import { DEVICE_DATA_FRAGMENT } from '../fragments/deviceData';

export const REGISTER_DEVICE_MUTATION = gql`
  mutation registerDevice {
    registerDevice {
      device {
        ...DeviceData
      }
    }
  }

  ${DEVICE_DATA_FRAGMENT}
`;
