import { gql } from '@apollo/client';
import { CARD_DATA_FRAGMENT } from '../fragments/cardData';

export const UNLINK_CARD_MUTATION = gql`
  mutation unlinkCard(
    $input: UnlinkCardMutationInput!
    $transactionsSkip: Int
    $transactionsLimit: Int
  ) {
    unlinkCard(input: $input) {
      card {
        ...CardData
      }
    }
  }

  ${CARD_DATA_FRAGMENT}
`;
