import { __assign } from "tslib";
import { useMemo } from 'react';
import { createHttpLink, split, from, InMemoryCache, ApolloClient, } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
export { GraphQLError } from 'graphql';
export function useApolloClient(options) {
    var httpLinkUrl = options.httpLinkUrl, websocketLinkUrl = options.websocketLinkUrl, bearerToken = options.bearerToken, cacheConfig = options.cacheConfig, onGraphQLErrors = options.onGraphQLErrors;
    var websocketLink = useMemo(function () {
        return new WebSocketLink({
            uri: websocketLinkUrl,
            options: {
                reconnect: true,
                connectionParams: {
                    authorization: bearerToken ? "Bearer ".concat(bearerToken) : '',
                },
            },
        });
    }, [websocketLinkUrl, bearerToken]);
    var httpLink = useMemo(function () {
        return createHttpLink({
            uri: httpLinkUrl,
        });
    }, [httpLinkUrl]);
    var authenticationLink = useMemo(function () {
        return setContext(function (_, _a) {
            var headers = _a.headers;
            return ({
                headers: __assign(__assign({}, headers), { authorization: bearerToken ? "Bearer ".concat(bearerToken) : '' }),
            });
        });
    }, [bearerToken]);
    var retryLink = useMemo(function () {
        return new RetryLink({
            delay: {
                initial: 100,
                max: Infinity,
                jitter: true,
            },
            attempts: {
                max: Infinity,
                retryIf: function (error) { return Boolean(error); },
            },
        });
    }, []);
    var errorLink = useMemo(function () {
        return onError(function (_a) {
            var graphQLErrors = _a.graphQLErrors;
            if (graphQLErrors) {
                onGraphQLErrors === null || onGraphQLErrors === void 0 ? void 0 : onGraphQLErrors(graphQLErrors, bearerToken);
            }
        });
    }, [onGraphQLErrors]);
    var websocketChain = useMemo(function () {
        return from([errorLink, websocketLink]);
    }, [errorLink, websocketLink]);
    var httpChain = useMemo(function () {
        return from([errorLink, retryLink, authenticationLink, httpLink]);
    }, [errorLink, retryLink, authenticationLink, httpLink]);
    var splitLink = useMemo(function () {
        return split(function (_a) {
            var query = _a.query;
            var definition = getMainDefinition(query);
            return (definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription');
        }, websocketChain, httpChain);
    }, [websocketChain, httpChain]);
    var client = useMemo(function () {
        return new ApolloClient({
            link: splitLink,
            cache: new InMemoryCache(cacheConfig),
            connectToDevTools: process.env.REACT_APP_ENV !== 'production',
            defaultOptions: {
                query: {
                    errorPolicy: 'all',
                },
                mutate: {
                    errorPolicy: 'all',
                },
            },
        });
    }, [splitLink, cacheConfig]);
    return client;
}
