import { __awaiter, __generator } from "tslib";
import { useState, useMemo, useEffect } from 'react';
import useAsync from 'react-use/lib/useAsync';
export function useFlashlight(props) {
    var _this = this;
    var _a = props.initialState, initialState = _a === void 0 ? false : _a, devices = props.devices, deviceLabel = props.deviceLabel;
    var _b = useState(initialState), isFlashlightOn = _b[0], setFlashlightOn = _b[1];
    var device = useMemo(function () { return getVideoDeviceByLabel({ devices: devices, deviceLabel: deviceLabel }); }, [devices, deviceLabel]);
    var _c = useAsync(function () { return getVideoTrack({ device: device }); }, [device]).value, track = _c === void 0 ? null : _c;
    var _d = useAsync(function () { return doesVideoTrackHaveFlashlightCapability({ track: track }); }, [track]).value, isFlashlightSupported = _d === void 0 ? false : _d;
    useEffect(function () {
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (!track) {
                            return [2 /*return*/, console.warn('Unable to find video device')];
                        }
                        if (!isFlashlightSupported) {
                            return [2 /*return*/, console.warn('Video device does not have a flashlight capability')];
                        }
                        return [4 /*yield*/, engageFlashlight({ track: track, desiredState: isFlashlightOn })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Unknown flashlight error: ' + JSON.stringify(error_1));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
        return function () {
            track === null || track === void 0 ? void 0 : track.stop();
        };
    }, [isFlashlightSupported, isFlashlightOn, track]);
    var state = {
        isFlashlightSupported: isFlashlightSupported,
        isFlashlightOn: isFlashlightOn,
    };
    var actions = {
        setFlashlightOn: setFlashlightOn,
    };
    return [state, actions];
}
function engageFlashlight(_a) {
    var track = _a.track, desiredState = _a.desiredState;
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, track.applyConstraints({
                        advanced: [{ torch: desiredState }],
                    })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
}
function doesVideoTrackHaveFlashlightCapability(_a) {
    var _b;
    var track = _a.track;
    return __awaiter(this, void 0, void 0, function () {
        var imageCapture, capabilities;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!track) {
                        return [2 /*return*/, false];
                    }
                    imageCapture = new ImageCapture(track);
                    return [4 /*yield*/, imageCapture.getPhotoCapabilities()];
                case 1:
                    capabilities = _c.sent();
                    return [2 /*return*/, (_b = capabilities.fillLightMode) === null || _b === void 0 ? void 0 : _b.includes('flash')];
            }
        });
    });
}
function getVideoTrack(_a) {
    var device = _a.device;
    return __awaiter(this, void 0, void 0, function () {
        var stream, track;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!device) {
                        return [2 /*return*/, null];
                    }
                    return [4 /*yield*/, navigator.mediaDevices.getUserMedia({
                            video: {
                                deviceId: device.deviceId,
                            },
                        })];
                case 1:
                    stream = _b.sent();
                    track = stream.getVideoTracks()[0];
                    return [2 /*return*/, track];
            }
        });
    });
}
function getVideoDeviceByLabel(_a) {
    var _b;
    var devices = _a.devices, deviceLabel = _a.deviceLabel;
    if (!isImageCaptureSupported()) {
        return null;
    }
    return ((_b = devices === null || devices === void 0 ? void 0 : devices.find(function (device) { return device.kind === 'videoinput' && device.label === deviceLabel; })) !== null && _b !== void 0 ? _b : null);
}
function isImageCaptureSupported() {
    return typeof ImageCapture === 'function';
}
