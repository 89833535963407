import { LegalText } from '../components/LegalText';
import { Stack } from '@chakra-ui/react';

export function PrivacyPage() {
  return (
    <Stack
      maxWidth="5xl"
      paddingX={4}
      align="left"
      paddingBottom={10}
      alignSelf="center"
    >
      <LegalText template="privacy" />
    </Stack>
  );
}
