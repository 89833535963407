import { Box, SimpleGrid, Text, useBreakpointValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BusinessItem } from './BusinessGrid';

export type SectionsProps = {
  template: string;
  subtree?: string;
  images: string[];
  replacements: Record<string, string>;
};

type ContentProps = {
  strings: any;
};

function ImageSectionContent(props: ContentProps) {
  const { strings } = props;
  return (
    <>
      {Object.entries(strings)
        .filter(([k, v]) => !k.startsWith('_') && k !== 'a')
        .map(([k, v]) => (
          <Text paddingTop={2}>{v as string}</Text>
        ))}
    </>
  );
}

export function ImageSections(props: SectionsProps) {
  const { template, subtree = 'iterate', images, replacements } = props;
  const { t } = useTranslation(template);
  const showLogo = useBreakpointValue({ base: false, sm: true });
  const body = t(subtree, { returnObjects: true, ...replacements }) as Object;
  let bodyImage = [];
  for (const [, sect] of Object.entries(body)) {
    bodyImage.push(sect);
  }
  for (const i in images) {
    bodyImage[i]._image = images[i];
  }

  return (
    <SimpleGrid
      minChildWidth={{ base: '16em', sm: '20em', lg: '24em' }}
      spacing={4}
    >
      {Object.entries(body).map(([, sect]) => {
        return (
          <BusinessItem
            backgroundColor="brand.100"
            imageHeight="sm"
            business={{
              photoUrl: sect._image,
              logoUrl: showLogo ? sect._image : undefined,
              name: sect._,
              address: { street: sect.__ },
            }}
          >
            <Box padding={4}>
              <Text>{sect.a}</Text>
              <ImageSectionContent strings={sect} />
            </Box>
          </BusinessItem>
        );
      })}
    </SimpleGrid>
  );
}
