import { gql } from '@apollo/client';
import { CARD_DATA_FRAGMENT } from '../fragments/cardData';

export const GET_CARD_QUERY = gql`
  query getCard(
    $_id: String!
    $transactionsSkip: Int
    $transactionsLimit: Int
  ) {
    card: getCard(_id: $_id) {
      ...CardData
    }
  }

  ${CARD_DATA_FRAGMENT}
`;
