import {
  Box,
  Stack,
  Text,
  Image,
  LinkBox,
  LinkOverlay,
  Skeleton,
  SkeletonText,
  LinkBoxProps,
  SystemProps,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { keyframes } from '@emotion/react';
import { ReactElement } from 'react';
import placeholderAsset from '../../assets/images/placeholder.png';
import { GutSteinIcon } from '../GutSteinIcon';

export type BusinessInfo = {
  logoUrl?: string;
  photoUrl: string;
  websiteUrl?: string;
  name: string;
  address?: { street?: string; city?: string };
};

type Props = LinkBoxProps & {
  business: BusinessInfo;
  imageHeight?: any;
  imageFit?: SystemProps['objectFit'];
  imageBackgroundColor?: SystemProps['color'];
  sweepingHighlight?: boolean;
};

const sweep = keyframes`
0% { background-position: 200% 0% }
100% { background-position: 0% 0% }
`;

function objectify(name: string): ReactElement {
  const gutStein = /^(.*)<gutSTEIN *\/>(.*)$/i.exec(name);
  if (gutStein) {
    const [, left, right] = gutStein;
    // gutSTEIN image proportions are pretty much 2:5
    return (
      <Text
        as="h4"
        marginTop={1}
        fontWeight="semibold"
        lineHeight="tight"
        isTruncated={false}
      >
        {left}
        <Box display="inline-block" position="relative" paddingX="0.2em">
          <GutSteinIcon
            height="2em"
            position="absolute"
            left="0"
            bottom="-0.55em"
          />
          <Box width="5em" />
        </Box>
        {right}
      </Text>
    );
  } else {
    return (
      <Text
        as="h4"
        marginTop={1}
        fontWeight="semibold"
        lineHeight="tight"
        isTruncated={true}
      >
        {name}
      </Text>
    );
  }
}

function cleanName(s: string): string {
  return s.replace(/<>\//g, '');
}

type MaybeLinkOverlayProps = {
  url?: string;
  children: ReactElement;
};

function MaybeLinkOverlay(props: MaybeLinkOverlayProps) {
  const { url, children } = props;
  if (url) {
    if (url.startsWith('/')) {
      return (
        <LinkOverlay as={RouterLink} href={url} to={url} isExternal={false}>
          {children}
        </LinkOverlay>
      );
    } else {
      return (
        <LinkOverlay href={url} to={url} isExternal={true}>
          {children}
        </LinkOverlay>
      );
    }
  } else {
    return children;
  }
}

export function BusinessItem(props: Props) {
  const {
    business,
    imageHeight,
    imageFit,
    imageBackgroundColor,
    sweepingHighlight,
    children,
    ...rest
  } = props;

  const sweepingProps = sweepingHighlight
    ? {
        bgGradient:
          'linear-gradient(135deg, black 80%, white 85% 87%, black 92%)',
        bgSize: '200% 100%',
        animation: `${sweep} 2.7s linear 3`,
      }
    : { backgroundColor: imageBackgroundColor ?? 'white' };

  return (
    <LinkBox
      borderWidth={2}
      borderTopRightRadius="3xl"
      borderBottomLeftRadius="3xl"
      boxShadow="md"
      overflow="hidden"
      {...rest}
    >
      <Box position="relative" width="full">
        <Image
          width="full"
          height={imageHeight ?? '3xs'}
          objectFit={imageFit ?? 'cover'}
          src={business.photoUrl}
          fallbackSrc={placeholderAsset}
          alt={cleanName(business.name)}
          {...sweepingProps}
        />
      </Box>

      <MaybeLinkOverlay url={business.websiteUrl}>
        <Stack direction="row" spacing={2} backgroundColor="white">
          {business.logoUrl ? (
            <Image
              alignSelf="center"
              margin={4}
              boxSize={10}
              borderRadius="full"
              src={business.logoUrl}
              fallbackSrc={placeholderAsset}
              alt={cleanName(business.name)}
            />
          ) : (
            <Box width={3} />
          )}

          <Box paddingY={4} fontFamily="Montserrat">
            {objectify(business.name)}

            <Text fontSize="sm" isTruncated={!!business.logoUrl} opacity={0.7}>
              {business.address?.street ?? business.address?.city}
            </Text>
          </Box>
        </Stack>
      </MaybeLinkOverlay>
      {children}
    </LinkBox>
  );
}

export function BusinessItemEmptyState() {
  return (
    <Stack
      borderWidth={2}
      borderTopRightRadius="3xl"
      borderBottomLeftRadius="3xl"
      boxShadow="md"
    >
      <Skeleton width="full" height="14em" borderTopRightRadius="3xl" />
      <SkeletonText padding={6} noOfLines={2} spacing="4" />
    </Stack>
  );
}
