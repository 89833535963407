import { gql } from '@apollo/client';
import { DEVICE_DATA_FRAGMENT } from '../fragments/deviceData';

export const ACTIVATE_DEVICE_MUTATION = gql`
  mutation activateDevice($input: ActivateDeviceMutationInput!) {
    activateDevice(input: $input) {
      device {
        ...DeviceData
      }
    }
  }

  ${DEVICE_DATA_FRAGMENT}
`;
