import { useMemo } from 'react';
import {
  Heading,
  Text,
  Button,
  useBreakpointValue,
  Stack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { shuffle } from '@trifence/utilities';
import { BusinessGrid } from '../components/BusinessGrid';
import { useGetActiveBusinessesQuery } from '../graphql/hooks/queries/getActiveBusinesses';
import { IoIosFlash } from 'react-icons/io';
import { keyframes } from '@emotion/react';
import { GutSteinIcon } from '../components/GutSteinIcon';
import { Helmet } from 'react-helmet';

const sweep = keyframes`
0% { background-position: 200% 0% }
100% { background-position: 0% 0% }
`;

type Props = {
  noHelmet?: boolean;
  skipHeader?: boolean;
  children?: React.ReactNode;
  replacements?: Record<string, string>;
};

export function Businesses(props: Props) {
  const { replacements = {}, noHelmet, skipHeader, children } = props;
  const { t } = useTranslation(['businesses', 'landing', 'info']);
  const offersText = useBreakpointValue({
    base: t('landing:offers_short'),
    md: t('landing:offers_long'),
  });
  const joinText = useBreakpointValue({
    base: t('join_short'),
    md: t('join_long'),
  });

  const getActiveBusinessesQuery = useGetActiveBusinessesQuery();

  const shuffledBusinesses = useMemo(() => {
    return shuffle(getActiveBusinessesQuery.data?.businesses ?? []);
  }, [getActiveBusinessesQuery.data?.businesses]);

  if (skipHeader) {
    return (
      <BusinessGrid
        isLoading={getActiveBusinessesQuery.loading}
        businesses={shuffledBusinesses}
      />
    );
  } else {
    return (
      <Stack maxWidth="70rem" padding={4} alignSelf="center">
        {noHelmet ? undefined : (
          <Helmet>
            <title>CompanyON: {t('shops')}</title>
          </Helmet>
        )}
        <Heading as="h1" color="brand.700" textAlign="left" size="2xl">
          {t('shops')}
        </Heading>
        <Text fontSize="2xl">{t('description', replacements)}</Text>
        <Button
          as={Link}
          to="/offers"
          colorScheme="brand"
          size="lg"
          width="100%"
          leftIcon={<IoIosFlash />}
          bgGradient="linear-gradient(135deg, #009ae6 90%, #abe7ff 91%, #009ae6 92%)"
          bgSize="200% 100%"
          animation={`${sweep} 4s linear 3`}
        >
          {offersText}
        </Button>
        <Heading as="h2" size="xl" color="brand.600">
          <GutSteinIcon height="1em" />
          {' • '}
          Stein am Rhein
          <Text as="span" color="brand.400">
            {' • '}Gewerbeverein Stein am Rhein
          </Text>
        </Heading>
        {children}

        <BusinessGrid
          isLoading={getActiveBusinessesQuery.loading}
          businesses={shuffledBusinesses}
        />
        <Button
          as={Link}
          to="/join"
          variant="outline"
          size="lg"
          width="100%"
          colorScheme="brand"
          borderWidth={2}
        >
          {joinText}
        </Button>
      </Stack>
    );
  }
}
