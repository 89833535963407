import { gql } from '@apollo/client';

export const DEVICE_DATA_FRAGMENT = gql`
  fragment DeviceData on Device {
    _id
    role
    ownerId
    createdAt
    activatedAt
  }
`;
