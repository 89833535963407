import { __assign, __rest } from "tslib";
import React from 'react';
import { Spinner } from '@chakra-ui/react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Stack } from '@chakra-ui/react';
export function InfiniteScrollList(props) {
    var children = props.children, canLoadMore = props.canLoadMore, onLoadMore = props.onLoadMore, rest = __rest(props, ["children", "canLoadMore", "onLoadMore"]);
    var sentryRef = useInfiniteScroll({
        loading: false,
        hasNextPage: canLoadMore,
        onLoadMore: onLoadMore,
        disabled: false,
        rootMargin: '0px 0px 400px 0px',
    })[0];
    return (React.createElement(Stack, __assign({}, rest),
        children,
        canLoadMore ? (React.createElement(Spinner, { ref: sentryRef, alignSelf: "center", marginY: 8, size: "lg", color: "brand.500" })) : null));
}
