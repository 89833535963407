import { gql } from '@apollo/client';
import { CARD_ATTACHMENT_DATA_FRAGMENT } from '../fragments/cardAttachmentData';

export const SET_CARD_ATTACHMENT_MUTATION = gql`
  mutation setCardAttachment($input: SetCardAttachmentMutationInput!) {
    setCardAttachment(input: $input) {
      cardAttachment {
        ...CardAttachmentData
      }
    }
  }

  ${CARD_ATTACHMENT_DATA_FRAGMENT}
`;
