import { useState, useEffect } from 'react';
import {
  Stack,
  HStack,
  Button,
  IconButton,
  useToast,
  Heading,
} from '@chakra-ui/react';
import {
  CardUrlScanner,
  ErrorIndicator,
  QRCodeScanner,
} from '@companyon/components';
import { IoIosCloseCircle } from 'react-icons/io';
import { RiCameraOffFill } from 'react-icons/ri';
import { MdFlashOff, MdFlashOn } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEvent } from 'react-use';
import { useMediaDevices, useFlashlight } from '@companyon/hooks';
import { isUrl, parseUrl, match } from '@trifence/utilities';
import { urls } from '@companyon/constants';
import { useStorage } from '@companyon/hooks';

type ScannerProps = {
  hideHeading?: boolean;
};

export function Scanner(props: ScannerProps) {
  const { hideHeading } = props;
  const [deviceLabel, setDeviceLabel] = useState('');

  const history = useHistory();
  const [storage, storageActions] = useStorage();
  const [{ devices, isVideoInputAvailable }] = useMediaDevices();
  const [{ isFlashlightSupported, isFlashlightOn }, { setFlashlightOn }] =
    useFlashlight({
      initialState: storage.isFlashlightOn,
      devices,
      deviceLabel,
    });
  const { t } = useTranslation('scanner');
  const addToast = useToast();

  useEvent('visibilitychange', handleCancelClick);

  useEffect(() => {
    storageActions.setFlashlightOn(isFlashlightOn);
  }, [isFlashlightOn, storageActions]);

  function handleLoad(deviceLabel: string) {
    setDeviceLabel(deviceLabel);
  }

  function handleScan(data: string) {
    const isValidUrl = isUrl(data);

    if (!isValidUrl) {
      addToast({
        status: 'warning',
        title: t('toast.invalidUrl'),
      });

      return history.goBack();
    }

    const parsedUrl = parseUrl(data);

    const customerProductionAliasHostnames =
      urls.web.customer.productionAliases.map((url) => parseUrl(url).hostname);

    const merchantProductionAliasHostnames =
      urls.web.merchant.productionAliases.map((url) => parseUrl(url).hostname);

    const merchantUrl = match(process.env.REACT_APP_ENV)
      .with('staging', () => urls.web.merchant.staging)
      .with('production', () => urls.web.merchant.production)
      .otherwise(() => urls.web.merchant.development);

    const customerWhitelist = match(process.env.REACT_APP_ENV)
      .with('staging', () => [
        parseUrl(urls.web.customer.staging).hostname,
        parseUrl(urls.web.customer.production).hostname,
        ...customerProductionAliasHostnames,
      ])
      .with('production', () => [
        parseUrl(urls.web.customer.production).hostname,
        ...customerProductionAliasHostnames,
      ])
      .otherwise(() => [
        parseUrl(urls.web.customer.development).hostname,
        parseUrl(urls.web.customer.staging).hostname,
        parseUrl(urls.web.customer.production).hostname,
        ...customerProductionAliasHostnames,
      ]);

    const merchantWhitelist = match(process.env.REACT_APP_ENV)
      .with('staging', () => [
        parseUrl(urls.web.merchant.staging).hostname,
        parseUrl(urls.web.merchant.production).hostname,
        ...merchantProductionAliasHostnames,
      ])
      .with('production', () => [
        parseUrl(urls.web.merchant.production).hostname,
        ...merchantProductionAliasHostnames,
      ])
      .otherwise(() => [
        parseUrl(urls.web.merchant.development).hostname,
        parseUrl(urls.web.merchant.staging).hostname,
        parseUrl(urls.web.merchant.production).hostname,
        ...merchantProductionAliasHostnames,
      ]);

    const isCustomerUrl = customerWhitelist.includes(parsedUrl.hostname);
    const isMerchantUrl = merchantWhitelist.includes(parsedUrl.hostname);

    if (!isCustomerUrl && !isMerchantUrl) {
      addToast({
        status: 'warning',
        title: t('toast.unsupportedUrl'),
      });

      return history.goBack();
    }

    /* Matches /card/a1b2c3 or /test/a1b2c3 pathnames */
    const isCardPathname = new RegExp(/^\/(card|test)\/\w+$/).test(
      parsedUrl.pathname,
    );

    if (isCustomerUrl && isCardPathname) {
      const [, , cardId] = parsedUrl.pathname.split('/');
      addToast({ title: t('toast.cardSuccessfullyScanned') });
      return history.push(`/card/${cardId}`);
    }

    /* Matches /register pathnames */
    const isRegistrationPathname = new RegExp(/^\/register$/).test(
      parsedUrl.pathname,
    );

    const { id: registrationId } = parsedUrl.query;

    if (isMerchantUrl && isRegistrationPathname && registrationId) {
      // Leaving the customer app, therefore cannot post a toast
      return window.location.assign(
        `${merchantUrl}/register?id=${registrationId}`,
      );
    }

    addToast({
      status: 'warning',
      title: t('toast.unsupportedUrl'),
    });
    return history.goBack();
  }

  function handleFlashlightClick() {
    setFlashlightOn((isFlashlightOn) => !isFlashlightOn);
  }

  function handleCancelClick() {
    history.push('/');
  }

  function handleDigitSequence(digitSequence: string) {
    history.push(`/card/${digitSequence}`);
  }

  if (isVideoInputAvailable === false) {
    return (
      <CardUrlScanner onNumber={handleDigitSequence}>
        <ErrorIndicator
          icon={<RiCameraOffFill size={120} />}
          heading={t('error.heading')}
          subheading={t('error.subheading')}
        />
      </CardUrlScanner>
    );
  }

  return (
    <Stack
      style={{ marginTop: 0 }}
      flex={1}
      justify="start"
      align="center"
      spacing={0}
    >
      <CardUrlScanner onNumber={handleDigitSequence}>
        <QRCodeScanner
          onLoad={handleLoad}
          onScan={handleScan}
          position="relative"
        >
          {hideHeading ? undefined : (
            <Heading
              as="h1"
              position="absolute"
              textColor="white"
              zIndex={1}
              top={0}
              width="100%"
              textAlign="center"
              paddingY={{ base: 1, sm: 4 }}
              marginBottom={0}
              fontSize={{ base: 'lg', md: '2xl' }}
            >
              {t('heading')}
            </Heading>
          )}
        </QRCodeScanner>
      </CardUrlScanner>

      <HStack marginBottom={16}>
        {isFlashlightSupported ? (
          <IconButton
            size="lg"
            icon={
              isFlashlightOn ? (
                <MdFlashOff size={24} />
              ) : (
                <MdFlashOn size={24} />
              )
            }
            aria-label="Toggle flashlight"
            onClick={handleFlashlightClick}
          />
        ) : null}

        <Button
          size="lg"
          colorScheme="gray"
          leftIcon={<IoIosCloseCircle />}
          onClick={handleCancelClick}
        >
          {t('cancel')}
        </Button>
      </HStack>
    </Stack>
  );
}
