import { useEffect } from 'react';
import { useLocation } from '@companyon/hooks';
var MAX_UPDATES = 10;
var DELAY = 50;
export function ScrollToTop() {
    var location = useLocation();
    var updates = 0;
    function stillAtTop() {
        if (window.scrollY > 0 && updates++ < MAX_UPDATES) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            setTimeout(stillAtTop, DELAY);
        }
    }
    useEffect(function () {
        window.scrollTo(0, 0);
        setTimeout(stillAtTop, DELAY);
    }, [location]);
    return null;
}
