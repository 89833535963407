import React from 'react';
import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, HStack, Button, } from '@chakra-ui/react';
export function Alerter(props) {
    var headerText = props.headerText, cancelText = props.cancelText, confirmationText = props.confirmationText, confirmationColorScheme = props.confirmationColorScheme, children = props.children, disclosure = props.disclosure, onConfirmation = props.onConfirmation, performingOperation = props.performingOperation;
    var cancelButtonRef = React.useRef(null);
    return (React.createElement(AlertDialog, { isCentered: true, leastDestructiveRef: cancelButtonRef, isOpen: disclosure.isOpen, onClose: disclosure.onClose },
        React.createElement(AlertDialogOverlay, null,
            React.createElement(AlertDialogContent, null,
                React.createElement(AlertDialogHeader, { fontSize: "lg", fontWeight: "bold" }, headerText),
                React.createElement(AlertDialogBody, null, children),
                React.createElement(AlertDialogFooter, null,
                    React.createElement(HStack, { spacing: 2 },
                        React.createElement(Button, { ref: cancelButtonRef, onClick: disclosure.onClose }, cancelText),
                        React.createElement(Button, { colorScheme: confirmationColorScheme, isLoading: performingOperation, onClick: onConfirmation }, confirmationText)))))));
}
