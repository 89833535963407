import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import { BusinessItem, BusinessItemEmptyState } from './BusinessItem';

type Props = SimpleGridProps & {
  isLoading?: boolean;
  businesses?: any;
};

export function BusinessGrid(props: Props) {
  const { isLoading = true, businesses = [], ...rest } = props;

  return (
    <SimpleGrid
      minChildWidth={{ base: '16em', lg: '18em' }}
      spacing={4}
      {...rest}
    >
      {(() => {
        if (isLoading) {
          const dummyArray = Array.from({ length: 20 }).fill(null);

          return dummyArray.map((_, index) => (
            <BusinessItemEmptyState key={index} />
          ));
        }

        return businesses.map((business: any) => (
          <BusinessItem business={business} key={business._id} />
        ));
      })()}
    </SimpleGrid>
  );
}
