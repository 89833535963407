import { LegalText } from '../components/LegalText';
import agbAsset from '../assets/images/agb.svg';
import { Stack, Image, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function AGBPage() {
  const { t } = useTranslation('agb');
  return (
    <Stack
      maxWidth="5xl"
      paddingX={4}
      align="left"
      paddingBottom={10}
      fontSize="lg"
      alignSelf="center"
    >
      <LegalText template="agb">
        <Stack padding={3} spacing={3} align="left" backgroundColor="brand.700">
          <Heading as="h2" color="white" paddingY={0}>
            {t('box.relationship')}
          </Heading>
          <Image src={agbAsset} maxWidth="lg" />
          <Text color="white" fontStyle="italic">
            {t('box.notBinding')}
          </Text>
        </Stack>
      </LegalText>
    </Stack>
  );
}
