import { gql } from '@apollo/client';
import { BUSINESS_DATA_FRAGMENT } from '../fragments/businessData';

export const GET_ACTIVE_BUSINESSES_QUERY = gql`
  query getActiveBusinesses {
    businesses: getActiveBusinesses {
      ...BusinessData
    }
  }

  ${BUSINESS_DATA_FRAGMENT}
`;
