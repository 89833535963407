import React from 'react';
import { Flex, Spacer, Text } from '@chakra-ui/react';
export function CurrencyPair(props) {
    var currency = props.currency, amount = props.amount;
    var numberString = amount.toFixed(0);
    if (numberString.length === 2) {
        numberString = '0' + numberString;
    }
    else if (numberString.length === 1) {
        numberString = '00' + numberString;
    }
    // Now at least 3 chars long
    var dotPosition = numberString.length - 2;
    var formattedNumber = numberString.substring(0, dotPosition) +
        '.' +
        numberString.substring(dotPosition, dotPosition + 2);
    return (React.createElement(Flex, { width: "100%" },
        React.createElement(Text, { paddingRight: "auto" }, currency),
        React.createElement(Spacer, { minWidth: "0.5em" }),
        React.createElement(Text, { paddingLeft: "auto" }, formattedNumber)));
}
