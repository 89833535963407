import {
  TabProps,
  Text,
  useTab,
  forwardRef,
  useStyles,
  chakra,
  SystemStyleObject,
  Badge,
} from '@chakra-ui/react';
import { cx, __DEV__ } from '@chakra-ui/utils';

interface IconTabProps extends TabProps {
  shrink?: boolean;
  newBadge?: string;
  leftIcon?: React.ReactElement;
}

export const IconTab = forwardRef<IconTabProps, 'button'>((props, ref) => {
  const { leftIcon, newBadge, shrink, fontSize, children, ...rest } = props;
  let shrunkChildren: React.ReactElement | undefined = (
    <Text fontSize={fontSize} isTruncated>
      {children}
    </Text>
  );
  if (shrink) {
    shrunkChildren = leftIcon;
  }
  const styles = useStyles();
  const tabProps = useTab({ ...rest, children: shrunkChildren, ref });

  const tabStyles: SystemStyleObject = {
    outline: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...styles.tab,
  };

  return (
    <chakra.button
      {...tabProps}
      className={cx('chakra-tabs__tab', props.className)}
      isTruncated
      __css={tabStyles}
    >
      {shrink ? null : <Text paddingX={2}>{leftIcon}</Text>}
      {shrunkChildren}
      {!shrink && newBadge ? (
        <Badge marginLeft={2} colorScheme="orange">
          {newBadge}
        </Badge>
      ) : null}
    </chakra.button>
  );
});

if (__DEV__) {
  IconTab.displayName = 'Tab';
}
