import {
  Image,
  Button,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import { LanguageSwitcher, NavigationBar } from '@companyon/components';
import { IoIosCloseCircle, IoIosFlash } from 'react-icons/io';
import { AiFillShop } from 'react-icons/ai';
import { MdHelp } from 'react-icons/md';
import { RiQrCodeLine } from 'react-icons/ri';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@companyon/hooks';
import logoAsset from '../assets/images/companyon.svg';

export function Header() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('landing');
  const { isOpen, onToggle, onClose } = useDisclosure();

  const boxShadow = useColorModeValue('md', 'none');
  const menuTextColor = useColorModeValue('brand.700', 'white');

  const regularButtonProps = {
    variant: 'outline',
    borderWidth: 2,
    borderColor: isOpen ? 'white' : 'brand.600',
    paddingX: 2,
    margin: 2,
    _hover: {
      background: ['brand.600', 'brand.600', 'brand.100', 'brand.100'],
    },
  };

  const isScanning = location.pathname === '/scan';

  function handleScanClick() {
    onClose();
    if (isScanning) {
      return history.goBack();
    } else {
      return history.push('/scan');
    }
  }

  return (
    <NavigationBar
      position="sticky"
      top="0"
      zIndex={999}
      background={['brand.500', 'brand.500', 'white', 'white']}
      color={['white', 'white', 'brand.700', 'brand.700']}
      logo={
        <Link to="/">
          <Image width={{ base: '25px', sm: '40px' }} src={logoAsset} />
        </Link>
      }
      boxShadow={boxShadow}
      isOpen={isOpen}
      onToggle={onToggle}
      onClose={onClose}
      paddingX={{ base: 4, lg: 6, xl: 8 }}
      paddingY={{ base: 2, lg: 4, xl: 6 }}
    >
      <Button
        as={Link}
        to="/shops"
        leftIcon={<AiFillShop />}
        onClick={onClose}
        {...regularButtonProps}
      >
        {t('navbar.shops')}
      </Button>

      <Button
        as={Link}
        to="/offers"
        leftIcon={<IoIosFlash />}
        onClick={onClose}
        {...regularButtonProps}
      >
        {t('navbar.offers')}
      </Button>

      <Button
        as={Link}
        to="/help"
        leftIcon={<MdHelp />}
        onClick={onClose}
        {...regularButtonProps}
      >
        {t('navbar.help')}
      </Button>

      <LanguageSwitcher
        menuTextColor={menuTextColor}
        onMenuClose={onClose}
        {...regularButtonProps}
      />

      <Button
        alignSelf="center"
        background={['white', 'white', 'brand.500', 'brand.500']}
        color={['brand.500', 'brand.500', 'white', 'white']}
        _hover={{
          background: ['brand.100', 'brand.100', 'brand.600', 'brand.600'],
        }}
        leftIcon={isScanning ? <IoIosCloseCircle /> : <RiQrCodeLine />}
        onClick={handleScanClick}
        margin={regularButtonProps.margin}
      >
        {isScanning ? t('navbar.cancel') : t('navbar.scan')}
      </Button>
    </NavigationBar>
  );
}
