import { useEffect } from 'react';
export function CardUrlScanner(props) {
    var children = props.children, onNumber = props.onNumber;
    var digitSequence = '';
    useEffect(function () {
        window.addEventListener('keydown', listenForCardUrl);
        return function () {
            window.removeEventListener('keydown', listenForCardUrl);
        };
    }, []);
    function listenForCardUrl(e) {
        // Be able to parse a URL from a wireless QR code scanner.
        // Depending on whether/how the keyboard layouts of scanner and computer
        // agree, many characters (especially ":" and "/", but also others),
        // will be scrambled. So just rely on what is behind the last non-digit
        // character.
        // As a result, every non-digit character resets the accumulated string,
        // every digit is appended to it, and it is set to the callback on Return.
        if (e.metaKey || e.ctrlKey || e.altKey) {
            return;
        }
        if ('0123456789'.includes(e.key)) {
            digitSequence = digitSequence + e.key;
        }
        else if (e.key === 'Enter' && digitSequence.length > 0) {
            onNumber(digitSequence);
            digitSequence = '';
        }
        else if (e.key.length === 1 && e.key !== ' ') {
            digitSequence = '';
        }
        else {
            digitSequence = '';
            return; // Mark as unhandled
        }
        e.preventDefault();
        e.stopPropagation();
    }
    return children;
}
