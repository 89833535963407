import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { manifestGeneratorUrl, selectAppEnv } from '@trifence/utilities';
import { useStorage, useLocation } from '@companyon/hooks';
import { ScrollToTop, Configuration } from '@companyon/components';

import { Header } from './pages/Header';
import { Landing } from './pages/Landing';
import { Scanner } from './pages/Scanner';
import { Card } from './pages/Card';
import { Businesses } from './pages/Businesses';
import { ErrorBoundary } from './pages/ErrorBoundary';
import { Footer } from './components/Footer';
import { Info } from './pages/Info';
import { Join } from './pages/Join';
import { Help } from './pages/Help';
import { Offers } from './pages/Offers';
import { AGBPage } from './pages/ABGPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { title } from './utilities/appName';
import companyonAsset from './assets/images/companyon.svg';
import { GutsteinLanding } from './pages/GutsteinLanding';

export function Page() {
  const location = useLocation();
  const [{ deviceIds, bearerTokens, language }] = useStorage();
  const { t } = useTranslation('landing');

  const description = t('subheading');
  const faviconUrl = selectAppEnv(['favicon.png', 'favicon-staging.png']);
  const appleTouchIconUrl = selectAppEnv([
    'logo192.png',
    'logo192-staging.png',
  ]);
  const manifestUrl = manifestGeneratorUrl(
    'customer',
    deviceIds,
    bearerTokens,
    language,
  );

  return (
    <>
      <Helmet>
        <title>
          {title}: {description}
        </title>
        <meta name="description" content={description} />
        <meta name="keywords" content={t('keywords')} />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={appleTouchIconUrl} />
        <meta name="msapplication-TileImage" content={appleTouchIconUrl} />
        <meta property="og:locale" content={language} />
        <meta property="og:site_name" content={`${title}: ${description}`} />
        <meta property="og:url" content={window.location.pathname} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={appleTouchIconUrl} />
        <meta property="og:image:url" content={companyonAsset} />
        <meta property="og:image:secure_url" content={companyonAsset} />
        <meta itemProp="name" content={title} />
        <meta itemProp="headline" content={`${title}: ${description}`} />
        <meta itemProp="image" content={companyonAsset} />
        <meta name="twitter:title" content={`${title}: ${description}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="manifest" href={manifestUrl} />
        <link rel="canonical" href={window.location.pathname} />
        <link
          rel="alternate"
          hrefLang="de"
          href={`${window.location.pathname}?language=de`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`${window.location.pathname}?language=en`}
        />
      </Helmet>

      <Header />
      <ScrollToTop />
      <Switch>
        <Route path="/" exact={true}>
          <Landing />
        </Route>

        <Route path="/gutstein">
          <GutsteinLanding />
        </Route>

        <Route path="/config">
          <Configuration />
        </Route>

        <Route path="/scan">
          <Scanner />
        </Route>

        <Route path="/help">
          <Help />
        </Route>

        <Route path="/info">
          <Info />
        </Route>

        <Route path="/join">
          <Join />
        </Route>

        <Route path="/offers">
          <Offers />
        </Route>

        <Route path="/agb">
          <AGBPage />
        </Route>

        <Route path="/privacy">
          <PrivacyPage />
        </Route>

        <Route path="/card/:cardId">
          <ErrorBoundary key={location.pathname}>
            <Card />
          </ErrorBoundary>
        </Route>

        <Route path="/shops">
          <ErrorBoundary key={location.pathname}>
            <Businesses replacements={{ brand: 'CompanyON' }} />
          </ErrorBoundary>
        </Route>

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}
