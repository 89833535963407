import React from 'react';
import { Box, HStack, SimpleGrid, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { possibleLngs } from '@companyon/constants';
function TextTracks(props) {
    var _a;
    /* Video naming convention: [path]/[1 char site][2 chars videoLanguage]-[actual name]
     * Returns a list of video tracks.
     * - If the page language matches the video language:
     *   - Captions for the video language: [path]/[site][videoLanguage]-[name].vtt, as default (=automatically enabled)
     *   - Subtitles for the other languages: [path]/[site][videoLanguage]-[name].[subtitleLanguage].vtt
     * - If the page language differs from the video language:
     *   - Captions for the video language: [path]/[site][videoLanguage]-[name].vtt
     *   - Subtitles for the other languages: [path]/[site][videoLanguage]-[name].[subtitleLanguage].vtt; the one in the page language marked as default
     */
    var pageLanguage = props.pageLanguage, baseName = props.baseName;
    // Hack: Disabled for all but cde-scan video for now (as there a no more captions right now)
    if (!baseName.endsWith('/cde-scan')) {
        return React.createElement(React.Fragment, null);
    }
    var videoLanguage = (_a = /^.*\/[a-z]([a-z]{2})-[a-z]*$/.exec(baseName)) === null || _a === void 0 ? void 0 : _a[1];
    return (React.createElement(React.Fragment, null, Object.entries(possibleLngs).map(function (_a) {
        var languageCode = _a[0], languageName = _a[1];
        return (React.createElement("track", { default: languageCode === pageLanguage, kind: languageCode === videoLanguage ? 'captions' : 'subtitles', srcLang: languageCode, label: languageName, src: baseName +
                (languageCode === videoLanguage ? '.vtt' : ".".concat(languageCode, ".vtt")) }));
    })));
}
export function VideoList(props) {
    var template = props.template, _a = props.subtree, subtree = _a === void 0 ? 'iterate' : _a;
    var _b = useTranslation(template), t = _b.t, language = _b.i18n.language;
    var body = t(subtree, { returnObjects: true });
    return (React.createElement(SimpleGrid, { minChildWidth: { base: '16em', sm: '20em', lg: '24em' }, spacing: 4, paddingBottom: 4 }, Object.entries(body).map(function (_a) {
        var sect = _a[1];
        return (React.createElement(Box, { borderWidth: 2, borderTopRightRadius: "3xl", borderBottomLeftRadius: "3xl", backgroundColor: "white", boxShadow: "md", overflow: "hidden", key: sect.url },
            React.createElement(Box, { position: "relative", width: "100%" },
                React.createElement("video", { width: "100%", controls: true, poster: sect.url + '.svg', preload: "none", crossOrigin: "anonymous" },
                    React.createElement("source", { src: sect.url + '.mp4', type: "video/mp4" }),
                    React.createElement(TextTracks, { pageLanguage: language, baseName: sect.url }),
                    React.createElement(Text, { textColor: "red", size: "lg" }, t('videoNotSupported')))),
            React.createElement(HStack, { spacing: 2 },
                React.createElement(Box, { padding: 4, fontFamily: "Montserrat", color: "blackAlpha.900" },
                    React.createElement(Text, { as: "h4", marginTop: 1, fontWeight: "semibold", lineHeight: "tight", isTruncated: true }, sect.title),
                    React.createElement(Text, { fontSize: "sm", opacity: 0.7 }, sect.goal)))));
    })));
}
