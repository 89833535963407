import { Stack, Heading, Button, useBreakpointValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RiQrCodeLine } from 'react-icons/ri';
import { ImageSections } from '../components/ImageSections';
import happyAsset from '../assets/images/happy.jpeg';
import trustAsset from '../assets/images/trust.jpeg';
import localAsset from '../assets/images/local.jpeg';
import luckAsset from '../assets/images/luck.jpeg';
import TextLoop from 'react-text-loop';
import { Helmet } from 'react-helmet';

export function Info() {
  const { t } = useTranslation(['info', 'landing']);
  const maybeBreak = useBreakpointValue({ base: <br />, xl: undefined });
  const replacements = {
    brand: t('companyonBrand'),
    regionalBrand: t('companyonRegionalBrand'),
  };

  return (
    <Stack
      maxWidth="5xl"
      alignSelf="center"
      marginX="auto"
      paddingX={{ base: 2, sm: 4, lg: 8 }}
      spacing={3}
    >
      <Helmet>
        <title>
          {[
            'CompanyON:',
            t('landing:heading.start'),
            t(
              'landing:heading.' + '0123'.charAt(Math.floor(Math.random() * 3)),
            ),
            t('landing:heading.end'),
          ].join(' ')}
        </title>
      </Helmet>
      <Heading
        as="h1"
        color="brand.700"
        textAlign="left"
        fontSize={{ base: '4xl', sm: '5xl', md: '6xl' }}
      >
        CompanyON — {t('landing:heading.start')}
        {maybeBreak}
        <TextLoop
          children={'0123'
            .split('')
            .map((i) => (t('landing:heading.' + i) as string | null) ?? '')}
        />
        {maybeBreak}
        {t('landing:heading.end')}
      </Heading>
      <ImageSections
        template="info"
        images={[happyAsset, trustAsset, luckAsset, localAsset]}
        replacements={replacements}
      />
      <Stack
        paddingX={{ base: undefined, md: 'auto' }}
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 2, md: 'auto' }}
        paddingTop={4}
        paddingBottom={8}
      >
        <Button
          as={Link}
          to="/join"
          variant="outline"
          size="lg"
          colorScheme="brand"
          backgroundColor="white"
          borderWidth={2}
        >
          {t('landing:who')}
        </Button>
        <Button
          as={Link}
          to="/shops"
          variant="outline"
          size="lg"
          colorScheme="brand"
          backgroundColor="white"
          borderWidth={2}
        >
          {t('landing:shops')}
        </Button>

        <Button
          as={Link}
          to="/scan"
          size="lg"
          colorScheme="brand"
          borderWidth={2}
          leftIcon={<RiQrCodeLine />}
        >
          {t('landing:scanCard')}
        </Button>
      </Stack>
    </Stack>
  );
}
