import { gql } from '@apollo/client';
import { CARD_DATA_FRAGMENT } from '../fragments/cardData';

export const LINK_CARD_MUTATION = gql`
  mutation linkCard(
    $input: LinkCardMutationInput!
    $transactionsSkip: Int
    $transactionsLimit: Int
  ) {
    linkCard(input: $input) {
      card {
        ...CardData
      }
    }
  }

  ${CARD_DATA_FRAGMENT}
`;
