import { __assign, __rest } from "tslib";
import React from 'react';
import { Button, Link } from '@chakra-ui/react';
import { IoIosArrowUp } from 'react-icons/io';
export function BackToTop(props) {
    var _a = props.children, children = _a === void 0 ? 'Back to top' : _a, rest = __rest(props, ["children"]);
    function handleClick() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return (React.createElement(Button, __assign({ as: Link, variant: "link", leftIcon: React.createElement(IoIosArrowUp, null), onClick: handleClick }, rest), children));
}
