import { useEffect, Suspense, useMemo } from 'react';
import { BrowserRouter, Link as RouterLink } from 'react-router-dom';
import { ChakraProvider, extendTheme, useToast, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import { ApolloProvider } from '@apollo/client';
import { useApolloClient } from '@companyon/graphql';
import { match, nowMillis, uniqueBy } from '@trifence/utilities';
import { colors, urls } from '@companyon/constants';
import { errors, i18nBackend as ErrorsBackend } from '@companyon/errors';
import { useStorage } from '@companyon/hooks';
import { CenteredSpinner } from '@companyon/components';
import { Page } from './Page';
import { initializeI18n } from './i18n';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/esteban/400.css';

// Error toast throttling
let lastDeviceDeletion: number | undefined;

initializeI18n({
  language: localStorage.getItem('language') ?? 'de',
  backends: [ErrorsBackend, HttpBackend],
});

export function App() {
  const [{ language }] = useStorage();
  const { i18n } = useTranslation('', { useSuspense: false });

  useEffect(() => {
    /* Set HTML lang attribute */
    document.documentElement.setAttribute('lang', language);
    /* Set react-i18next language */
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const theme = useMemo(() => {
    return extendTheme({
      colors,
      fonts: {
        body: 'Esteban',
        heading: 'Montserrat',
      },
      global: {
        h1: { color: 'brand.700' }, // Does not seem to work
      },
      components: {
        Link: {
          baseStyle: { textDecoration: 'underline' },
          defaultProps: { as: RouterLink }, // Does not seem to work?
        },
        Button: {
          baseStyle: { fontFamily: 'Montserrat', fontWeight: 600 },
        },
        Text: { baseStyle: { fontStyle: 'normal' } },
        Heading: { baseStyle: { fontWeight: 300 } },
      },
      config: {
        initialColorMode: 'light',
        useSystemColorMode: false,
      },
    });
  }, []);

  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Suspense fallback={<CenteredSpinner />}>
          <ConfiguredApolloProvider>
            <Stack spacing={0} minHeight="100vh">
              <Page />
            </Stack>
          </ConfiguredApolloProvider>
        </Suspense>
      </ChakraProvider>
    </BrowserRouter>
  );
}

type DBProps = { children: React.ReactElement };

function ConfiguredApolloProvider(props: DBProps) {
  const { children } = props;
  const [{ deviceId }, { deleteDeviceId }] = useStorage();
  const { t } = useTranslation('_errors');
  const addToast = useToast();

  const apiHttpUrl = useMemo(() => {
    return match(process.env.REACT_APP_ENV)
      .with('staging', () => urls.api.http.staging)
      .with('production', () => urls.api.http.production)
      .otherwise(() => urls.api.http.development);
  }, []);

  const apiWebsocketUrl = useMemo(() => {
    return match(process.env.REACT_APP_ENV)
      .with('staging', () => urls.api.webSocket.staging)
      .with('production', () => urls.api.webSocket.production)
      .otherwise(() => urls.api.webSocket.development);
  }, []);

  const merge = (existing: any, incoming: any, { args }: any) => {
    const { skip } = args;

    const merged = existing ? existing.slice(0) : [];
    const updates = incoming ?? [];

    for (let i = 0; i < updates.length; ++i) {
      merged[skip + i] = updates[i];
    }

    return uniqueBy(merged, '__ref');
  };

  const apolloClient = useApolloClient({
    httpLinkUrl: `${apiHttpUrl}/graphql`,
    websocketLinkUrl: apiWebsocketUrl,
    bearerToken: deviceId,
    cacheConfig: {
      typePolicies: {
        Card: {
          fields: {
            transactions: {
              keyArgs: false,
              merge,
            },
            attachments: {
              keyArgs: false,
            },
          },
        },
      },
    },
    onGraphQLErrors: (graphQLErrors) => {
      graphQLErrors.forEach((graphQLError) => {
        const { message } = graphQLError;

        if (
          message === errors.authorization.HEADER_NOT_PROVIDED ||
          message === errors.authorization.DEVICE_NOT_AUTHORIZED ||
          message === errors.notFound.DEVICE_NOT_FOUND ||
          message === errors.notFound.DEVICE_DELETED
        ) {
          if (!lastDeviceDeletion || lastDeviceDeletion + 10000 < nowMillis()) {
            lastDeviceDeletion = nowMillis();
            deleteDeviceId(deviceId);
            addToast({
              status: 'error',
              title: t(message),
              duration: 30000,
            });
          }
        } else {
          addToast({
            status: 'error',
            title: t(message),
          });
        }
      });
    },
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
