import React from 'react';
import { ErrorIndicator } from '@companyon/components';
import { Translation } from 'react-i18next';

type Props = {};

type State = {
  error: Error | null;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <Translation>
          {(t) => <ErrorIndicator heading={t(error.message)} />}
        </Translation>
      );
    }

    return children;
  }
}
