import { gql } from '@apollo/client';

// `videoUrl` is being transitioned to `url` (i.e., it is @DEPRECATED)
export const CARD_ATTACHMENT_DATA_FRAGMENT = gql`
  fragment CardAttachmentData on CardAttachment {
    _id
    cardId
    type
    url
    videoUrl
    thumbnailUrl
    transcodedUrl
    description
    attributes
    expiresAt
  }
`;
