import {
  Flex,
  Heading,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export type LegalTextProps = {
  titleField?: string;
  validField?: string;
  template: string;
  subtree?: string;
  children?: ReactElement;
};

const highlightColors = [
  'brand.100',
  'orange.100',
  'green.100',
  'yellow.100',
  'blue.100',
  'purple.100',
  'pink.100',
  'red.100',
  'teal.100',
];

function splitKeywords(s: string): string[] {
  const regexp = /\[[^\][]*\]/;
  let match;
  let retval: string[] = [];
  while ((match = regexp.exec(s)) !== null) {
    retval.push(s.substring(0, match.index), match[0]);
    s = s.substring(match.index + match[0].length);
  }
  if (s.length > 0) {
    retval.push(s);
  }
  return retval;
}

function markupKeywords(
  s: string[],
  kwColor: (kw: string) => string,
): ReactElement[] {
  return s.map((maybeKw) => {
    if (maybeKw.startsWith('[')) {
      const regexp = /\[([^\][|]*)(\|([^\][]*))?\]/; // Is guaranteed to match, but TypeScript does not know this
      const match = maybeKw.match(regexp);
      const color = kwColor(match?.[3] ?? match?.[1] ?? '');
      return (
        <Text as="span" backgroundColor={color}>
          {match?.[1] ?? ''}
        </Text>
      );
    } else {
      return <>{maybeKw}</>;
    }
  });
}

function highlightSentence(
  s: string,
  kwColor: (kw: string) => string,
): ReactElement[] {
  const separated = splitKeywords(s);
  return markupKeywords(separated, kwColor);
}

export function LegalText(props: LegalTextProps) {
  const {
    template,
    validField = 'valid',
    titleField = 'title',
    subtree = 'iterate',
    children,
  } = props;
  const { t } = useTranslation(template);
  const alternateColor = useColorModeValue('brand.50', 'brand.900');

  const body = t(subtree, { returnObjects: true }) as Object;

  let everything: ReactElement[] = [];
  let sections: ReactElement[] = [];
  let keywordColors: Record<string, string> = {};
  let colorsUsed: number = 0;

  function kwColor(kw: string): string {
    if (keywordColors[kw] !== undefined) {
      return keywordColors[kw];
    } else {
      const nextColor = highlightColors[colorsUsed++];
      keywordColors[kw] = nextColor;
      return keywordColors[kw];
    }
  }

  function finishAlternatingSectionMaybe() {
    if (sections.length) {
      everything.push(
        <VStack
          padding={3}
          spacing={3}
          align="left"
          backgroundColor={
            everything.length % 2 !== 0 ? alternateColor : undefined
          }
        >
          {sections}
        </VStack>,
      );
      sections = [];
    }
  }

  for (const [sectKey, sect] of Object.entries(body)) {
    if (typeof sect === 'object') {
      for (const [key, value] of Object.entries(sect)) {
        if (typeof value === 'string') {
          if (key.startsWith('__')) {
            sections.push(
              <Heading
                fontSize="2xl"
                color="brand.400"
                style={{ marginTop: '0px' }}
                key={`${sectKey}.${key}`}
              >
                {highlightSentence(value, kwColor)}
              </Heading>,
            );
          } else if (key.startsWith('_')) {
            finishAlternatingSectionMaybe();
            sections.push(
              <Heading color="brand.700" paddingY={0} key={`${sectKey}.${key}`}>
                {highlightSentence(value, kwColor)}
              </Heading>,
            );
          } else if (key.startsWith('/')) {
            sections.push(
              <Flex direction="row">
                {value.split(' //// ').map((block) => (
                  <>
                    <Text lineHeight="110%">
                      {block.split(' // ').map((line) => (
                        <>
                          {line}
                          <br />
                        </>
                      ))}
                    </Text>
                    <Spacer maxWidth="5em" />
                  </>
                ))}
              </Flex>,
            );
          } else {
            sections.push(
              <Text key={`${sectKey}.${key}`}>
                {highlightSentence(value, kwColor)}
              </Text>,
            );
          }
        } else if (typeof value === 'object' && value && key.startsWith('*')) {
          sections.push(
            <UnorderedList
              borderStyle="solid"
              borderLeftWidth="thin"
              borderRightWidth="thin"
              borderColor="brand.500"
              paddingX={8}
            >
              {Object.entries(value).map(([k, v]) => (
                <ListItem key={k}>{v}</ListItem>
              ))}
            </UnorderedList>,
          );
        } else {
          console.warn('Unexpected nesting: ' + JSON.stringify(value));
        }
      }
    } else {
      // Top-level not object: Used for annotations
      sections.push(<Text fontStyle="italic">{sect}</Text>);
    }
  }
  finishAlternatingSectionMaybe();

  const languageComment = t('languageComment');
  return (
    <>
      <Helmet>
        <title>CompanyON: {t(titleField)}</title>
      </Helmet>
      <Heading as="h1" color="brand.700" padding={3} size="2xl">
        {t(titleField)}
      </Heading>
      {languageComment ? (
        <Text padding={3} backgroundColor="brand.200">
          {t('languageComment')}
        </Text>
      ) : undefined}
      <Text color="brand.500" paddingX={3}>
        {t(validField)}
      </Text>
      {children}
      {everything}
    </>
  );
}
