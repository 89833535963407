import { __assign, __spreadArray } from "tslib";
import { useLocalStorage } from '@rehooks/local-storage';
import { cutDownToUuidArray, cutDownToUuidToMaybeUuid, destructure, isLng, nowMillis, } from '@trifence/utilities';
import { lngOptions, times, keys } from '@companyon/constants';
// Language selection priority:
// 1: Saved in `localStorage['language']` (explicitely switched by user; highest)
// 2: Specified in `language` query string (for search engines `hreflang`)
// 3: Browser language settings (regular users)
function browserLanguage() {
    // Query string override?
    var urlSearchParams = new URLSearchParams(window.location.search);
    var queryLanguage = urlSearchParams.get('language');
    if (queryLanguage && isLng(queryLanguage)) {
        return queryLanguage;
    }
    for (var _i = 0, _a = (navigator === null || navigator === void 0 ? void 0 : navigator.languages) || []; _i < _a.length; _i++) {
        var l = _a[_i];
        // Handling exact matches
        if (isLng(l)) {
            return l;
        }
        // Handling matches of the first part of a 'de-CH' etc. browser language
        var dash = l.indexOf('-');
        if (dash >= 0) {
            var partialLng = l.substring(0, dash);
            if (isLng(partialLng)) {
                return partialLng;
            }
        }
    }
    return lngOptions[0];
}
/*
 * At the time any of the `set` functions is called, the `get` value might be stale
 * (possibly several API RTTs old); this is especially noticeable when multiple tabs
 * are open.
 *
 * So, anything that performs checks on those database-related states or modifies them,
 * should do those checks/modifications against the "real time" data, not the possible
 * stale state that lead to this check/modification.
 */
export function realTimeDeviceId() {
    var _a;
    return (_a = localStorage[keys.DEVICE_ID]) !== null && _a !== void 0 ? _a : '';
}
export function realTimeDeviceIds() {
    return cutDownToUuidArray(destructure(keys.DEVICE_IDS));
}
export function realTimeBlacklist() {
    return cutDownToUuidArray(destructure(keys.ID_BLACKLIST));
}
export function realTimeBearerTokens() {
    return cutDownToUuidToMaybeUuid(destructure(keys.BEARER_TOKEN));
}
export function arraysEqual(a, b) {
    return a.length === b.length && a.every(function (value, index) { return value === b[index]; });
}
export function recordsEqual(a, b) {
    var aEntries = Object.entries(a);
    var bEntries = Object.entries(b);
    return (aEntries.length === bEntries.length &&
        aEntries.every(function (_a) {
            var k = _a[0], v = _a[1];
            return b.hasOwnProperty(k) && b[k] === v;
        }));
}
export function useStorage() {
    var _a = useLocalStorage(keys.DEVICE_ID, ''), deviceId = _a[0], setDeviceId = _a[1], removeDeviceId = _a[2];
    var _b = useLocalStorage(keys.DEVICE_IDS, []), deviceIds = _b[0], setDeviceIds = _b[1];
    var _c = useLocalStorage(keys.ID_BLACKLIST, []), idBlacklist = _c[0], setIdBlacklist = _c[1];
    var _d = useLocalStorage(keys.BEARER_TOKEN, {}), bearerTokens = _d[0], setInternalBearerToken = _d[1];
    var _e = useLocalStorage(keys.LANGUAGE), language = _e[0], setLanguage = _e[1];
    var _f = useLocalStorage(keys.IS_FLASHLIGHT_ON, false), isFlashlightOn = _f[0], setFlashlightOn = _f[1];
    var _g = useLocalStorage(keys.DEMO_MODE_UNTIL, undefined), demoModeUntil = _g[0], setDemoModeUntil = _g[1], removeDemoModeUntil = _g[2];
    if (demoModeUntil &&
        (demoModeUntil > nowMillis() + times.ONE_HOUR_IN_MILLIS ||
            demoModeUntil < nowMillis())) {
        // Do not allow activating demo mode for more than an hour at a stretch for
        // security reasons (i.e., users are normally made aware of being in staging).
        // Being radical here is simpler.
        removeDemoModeUntil();
    }
    function setDemoMode(active) {
        if (active) {
            setDemoModeUntil(nowMillis() + times.ONE_HOUR_IN_MILLIS);
        }
        else {
            removeDemoModeUntil();
        }
    }
    function pickDeviceId(deviceId) {
        if (!deviceId) {
            console.info('Invalid deviceId');
            return;
        }
        var rtDeviceIds = realTimeDeviceIds();
        if (!rtDeviceIds.includes(deviceId)) {
            setDeviceIds(rtDeviceIds.concat(deviceId));
        }
        setDeviceId(deviceId);
    }
    function addIdToBlacklist(deviceId) {
        var rtBlacklist = realTimeBlacklist();
        if (!arraysEqual(rtBlacklist, idBlacklist)) {
            console.log({
                raceCondition: 'deleteIdFromBlacklist',
                saved: idBlacklist,
                realTime: rtBlacklist,
            });
        }
        if (!rtBlacklist.includes(deviceId)) {
            console.log("Add ".concat(deviceId, " to blacklist"));
            setIdBlacklist(__spreadArray(__spreadArray([], rtBlacklist, true), [deviceId], false));
        }
    }
    function removeIdFromBlacklist(deviceId) {
        var rtBlacklist = realTimeBlacklist();
        if (!arraysEqual(rtBlacklist, idBlacklist)) {
            console.log({
                raceCondition: 'deleteIdFromBlacklist',
                saved: idBlacklist,
                realTime: rtBlacklist,
            });
        }
        if (rtBlacklist.includes(deviceId)) {
            console.log("Remove ".concat(deviceId, " from blacklist"));
            setIdBlacklist(rtBlacklist.filter(function (item) { return item !== deviceId; }));
        }
    }
    function deleteDeviceId(deviceId) {
        var _a;
        var rtDeviceId = realTimeDeviceId();
        var rtDeviceIds = realTimeDeviceIds();
        if (rtDeviceId !== deviceId) {
            console.log("Not deleting deviceId ".concat(deviceId, ", as ID changed to ").concat(rtDeviceId, " in the meantime."));
            return;
        }
        if (!arraysEqual(rtDeviceIds, deviceIds)) {
            console.log({
                raceCondition: 'deleteDeviceId',
                toBeDeleted: deviceId,
                saved: deviceIds,
                realTime: rtDeviceIds,
            });
        }
        var updatedDeviceIds = realTimeDeviceIds().filter(function (id) { return id !== deviceId; });
        // First non-empty device ID, '' otherwise
        var updatedDeviceId = (_a = updatedDeviceIds.find(Boolean)) !== null && _a !== void 0 ? _a : '';
        setDeviceIds(updatedDeviceIds);
        addIdToBlacklist(deviceId);
        if (deviceId === '') {
            removeDeviceId();
        }
        else {
            setDeviceId(updatedDeviceId);
        }
    }
    function setBearerToken(deviceId, bearerToken) {
        var _a;
        var rtBearerTokens = realTimeBearerTokens();
        if (!recordsEqual(rtBearerTokens, bearerTokens)) {
            console.log({
                raceCondition: 'deleteDeviceId',
                newDeviceId: deviceId,
                newBearerToken: bearerToken,
                saved: bearerTokens,
                realTime: rtBearerTokens,
            });
        }
        if (!rtBearerTokens.hasOwnProperty(deviceId) ||
            rtBearerTokens[deviceId] !== bearerToken) {
            // "Evil" deviceIds (e.g., containing `prototype`/`__proto__`) *might*
            // trigger client-side malfunctions. Propagation to server side is not
            // possible, however. And client-side malfunctions can always be
            // triggered by other means (code modifications). This might just be
            // more persistent.
            setInternalBearerToken(__assign(__assign({}, rtBearerTokens), (_a = {}, _a[deviceId] = bearerToken, _a)));
        }
    }
    var state = {
        deviceId: deviceId,
        deviceIds: deviceIds,
        idBlacklist: idBlacklist,
        bearerToken: deviceId ? bearerTokens[deviceId] : undefined,
        bearerTokens: bearerTokens,
        language: language !== null && language !== void 0 ? language : browserLanguage(),
        isFlashlightOn: isFlashlightOn,
        demoMode: !!demoModeUntil && demoModeUntil > nowMillis(),
    };
    var actions = {
        pickDeviceId: pickDeviceId,
        deleteDeviceId: deleteDeviceId,
        addIdToBlacklist: addIdToBlacklist,
        removeIdFromBlacklist: removeIdFromBlacklist,
        setBearerToken: setBearerToken,
        setLanguage: setLanguage,
        setFlashlightOn: setFlashlightOn,
        setDemoMode: setDemoMode,
    };
    return [state, actions];
}
