import { gql } from '@apollo/client';
import { CARD_ATTACHMENT_DATA_FRAGMENT } from '../fragments/cardAttachmentData';

export const DELETE_CARD_ATTACHMENT_MUTATION = gql`
  mutation deleteCardAttachment($input: DeleteCardAttachmentMutationInput!) {
    deleteCardAttachment(input: $input) {
      cardAttachment {
        ...CardAttachmentData
      }
    }
  }

  ${CARD_ATTACHMENT_DATA_FRAGMENT}
`;
