import { Box, Text } from '@chakra-ui/react';
import { BusinessItem } from './BusinessGrid';
import { keyframes } from '@emotion/react';
import tencentAsset from '../assets/images/10percent.svg';
import { useTranslation } from 'react-i18next';

const sweep = keyframes`
0% { background-position: 200% 0% }
100% { background-position: 0% 0% }
`;

export function SpecialOffers() {
  // Borrowed from https://stackoverflow.com/a/31732310
  // Used for a bad hack, as the text background gradient animation won't work,
  // just disabling it (for now), not investing time to fix it properly.
  const isSafari =
    navigator?.vendor?.indexOf('Apple') >= 0 &&
    navigator.userAgent?.indexOf('CriOS') < 0 &&
    navigator.userAgent.indexOf('FxiOS') < 0;

  const { t } = useTranslation('offers');

  return (
    <BusinessItem
      backgroundColor="brand.100"
      imageBackgroundColor="black"
      sweepingHighlight
      imageFit="contain"
      business={{
        logoUrl: 'https://uploads.companyon.ch/api/file/wundernas-logo.svg',
        photoUrl: tencentAsset,
        websiteUrl: 'https://wundernas.ch',
        name: t('wundernas.shop'),
        address: { street: t('wundernas.offer') },
      }}
    >
      <Box padding={4}>
        <Text>
          {t('wundernas.1')}
          {isSafari ? (
            <Text as="span" fontWeight="bold">
              {t('wundernas.2')}
            </Text>
          ) : (
            <Text
              as="span"
              bgGradient="linear-gradient(135deg, black 80%, white, black 90%)"
              bgSize="200% 100%"
              bgClip="text"
              animation={`${sweep} 2s linear 4`}
            >
              {t('wundernas.2')}
            </Text>
          )}
        </Text>
        <Text paddingTop={2}>{t('wundernas.3')}</Text>
      </Box>
    </BusinessItem>
  );
}
