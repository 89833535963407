import { Box, Stack } from '@chakra-ui/react';
import { Landing } from './Landing';
import { GutSteinDashIcon, GutSteinIcon } from '../components/GutSteinIcon';
import { Businesses } from './Businesses';
import { Helmet } from 'react-helmet';

export function GutsteinLanding() {
  return (
    <>
      <Helmet>
        <title>
          gutSTEIN • Die Geschenkkarte für Stein am Rhein und Umgebung
        </title>
        <meta
          name="description"
          content="gutSTEIN ist mehr als nur eine Geschenkkarte. Für Stein am Rhein und Region angeboten vom Gewerbeverein Stein am Rhein auf Basis der CompanyON-Technologie der Trifence AG."
        />
        <meta
          itemProp="headline"
          content="gutSTEIN • Die Geschenkkarte für Stein am Rhein und Umgebung"
        />
      </Helmet>
      <Landing
        branding={{
          brandLogo: (
            <Box align="center" marginX="auto" marginBottom="-0.2em">
              <GutSteinIcon height="1.5em" />
              <GutSteinDashIcon height="1.5em" />
            </Box>
          ),
          splitAfterLogo: false,
          brandKey: 'info:gutsteinBrand',
          regionalBrandKey: 'info:gutsteinRegionalBrand',
        }}
      >
        <Stack size="3xl">
          <Businesses replacements={{ brand: 'gutSTEIN' }} />
        </Stack>
      </Landing>
    </>
  );
}
