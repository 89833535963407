import { __assign, __rest } from "tslib";
import React, { forwardRef } from 'react';
import { Box } from '@chakra-ui/react';
import QrReader from 'react-qr-reader-es6';
var QRReader = QrReader;
export var QRCodeScanner = forwardRef(function (props, ref) {
    var _a = props.resolution, resolution = _a === void 0 ? 1000 : _a, onLoad = props.onLoad, onScan = props.onScan, onError = props.onError, children = props.children, rest = __rest(props, ["resolution", "onLoad", "onScan", "onError", "children"]);
    function handleLoad(data) {
        onLoad === null || onLoad === void 0 ? void 0 : onLoad(data.streamLabel);
    }
    function handleScan(data) {
        if (!data) {
            return;
        }
        onScan === null || onScan === void 0 ? void 0 : onScan(data);
    }
    function handleError(error) {
        onError === null || onError === void 0 ? void 0 : onError(error);
    }
    return (React.createElement(Box, __assign({ width: { base: 'full', md: '75%', lg: '50%', xl: '50%' } }, rest),
        React.createElement(QRReader, { ref: ref, resolution: resolution, onLoad: handleLoad, onScan: handleScan, onError: handleError }),
        children));
});
