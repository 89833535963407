import React, { useState, useRef } from 'react';
import {
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Progress,
} from '@chakra-ui/react';
import { MdCheckCircle, MdCancel } from 'react-icons/md';
import { IoVideocam } from 'react-icons/io5';
import { motion } from 'framer-motion';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { get } from '@trifence/utilities';
import { UploadState, Locale, Translations } from './types';

type Props = {
  allowedFileTypes?: string[];
  upload: {
    url: string;
    fieldName?: string;
    headers?: Record<string, string>;
  };
  locale?: Locale;
  translations?: Translations;
  onClose?: () => void;
  onUploadSuccess?: (response: AxiosResponse) => void;
  onUploadFailure?: (error: AxiosError) => void;
};

export function VideoUploadModal(props: Props) {
  const {
    allowedFileTypes,
    upload: { url, fieldName = 'file', headers = {} },
    locale = 'en',
    translations = {
      en: {
        heading: {
          IDLE: 'Upload file',
          UPLOADING: 'Uploading…',
          UPLOAD_SUCCESS: 'Upload complete!',
          UPLOAD_FAILURE: 'Upload failed!',
        },
      },
    },
    onClose,
    onUploadSuccess,
    onUploadFailure,
  } = props;

  const [uploadState, setUploadState] = useState<UploadState>('IDLE');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const heading = get([locale, 'heading', uploadState])(translations);
  const subheading = get([locale, 'subheading', uploadState])(translations);

  function handleUploadClick() {
    inputRef?.current?.click();
  }

  function handleUploadStart() {
    setUploadState('UPLOADING');
  }

  function handleUploadProgress(event: any) {
    const { loaded, total } = event;
    setUploadProgress((loaded / total) * 100);
  }

  function handleUploadSuccess(response: AxiosResponse) {
    setUploadState('UPLOAD_SUCCESS');
    onUploadSuccess?.(response);
  }

  function handleUploadFailure(error: AxiosError) {
    setUploadState('UPLOAD_FAILURE');
    onUploadFailure?.(error);
  }

  function handleClose() {
    /* TODO: Terminate HTTP request if currently uploading */
    onClose?.();
    setUploadState('IDLE');
  }

  async function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files || event.target.files.length < 1) {
      return;
    }

    const file = event.target.files[0];

    const formData = new FormData();
    formData.append(fieldName, file);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers,
      },
      onUploadProgress: handleUploadProgress,
    };

    try {
      handleUploadStart();
      const response = await axios.post(url, formData, config);
      handleUploadSuccess(response);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleUploadFailure(error);
      } else {
        console.error('handleFileSelect error: ' + JSON.stringify(error));
      }
    }
  }

  return (
    <Modal isCentered={true} isOpen={true} onClose={handleClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalBody paddingX={12} paddingY={16}>
          <VStack spacing={8}>
            <VStack>
              <Text fontSize="2xl" fontWeight="500" textAlign="center">
                {heading}
              </Text>

              <Text fontSize="xl" textAlign="center" opacity={0.7}>
                {subheading}
              </Text>
            </VStack>

            {(() => {
              if (uploadState === 'IDLE') {
                return (
                  <motion.div
                    style={{
                      padding: 16,
                      borderRadius: '50%',
                      backgroundColor: '#009ae6',
                      cursor: 'pointer',
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={handleUploadClick}
                  >
                    <IoVideocam size={64} color="white" />
                  </motion.div>
                );
              }

              if (uploadState === 'UPLOADING') {
                return (
                  <Progress
                    size="md"
                    width="full"
                    colorScheme="brand"
                    hasStripe={true}
                    value={uploadProgress}
                  />
                );
              }

              if (uploadState === 'UPLOAD_SUCCESS') {
                return <MdCheckCircle size={80} color="#00C781" />;
              }

              if (uploadState === 'UPLOAD_FAILURE') {
                return <MdCancel size={80} color="#FF4040" />;
              }

              return null;
            })()}

            <input
              ref={inputRef}
              hidden={true}
              type="file"
              multiple={false}
              accept={allowedFileTypes?.join(',')}
              onChange={handleFileSelect}
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
