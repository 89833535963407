import { __assign, __rest } from "tslib";
import React from 'react';
import { Stack, Heading } from '@chakra-ui/react';
import { MdError } from 'react-icons/md';
export function ErrorIndicator(props) {
    var _a = props.icon, icon = _a === void 0 ? React.createElement(MdError, { size: 120 }) : _a, _b = props.heading, heading = _b === void 0 ? 'Something went wrong' : _b, subheading = props.subheading, rest = __rest(props, ["icon", "heading", "subheading"]);
    return (React.createElement(Stack, __assign({ flex: 1, justify: "center", align: "center", padding: { base: 4, md: 8 }, spacing: 8 }, rest),
        icon,
        React.createElement(Stack, { spacing: 2 },
            React.createElement(Heading, { as: "h1", color: "brand.700", textAlign: "center", fontSize: { base: '3xl', md: '4xl' } }, heading),
            subheading ? (React.createElement(Heading, { as: "h2", textAlign: "center", fontSize: { base: '2xl', md: '3xl' }, fontWeight: "normal", opacity: 0.7 }, subheading)) : null)));
}
