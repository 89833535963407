import { __assign, __rest, __spreadArray } from "tslib";
import React, { memo, useMemo } from 'react';
import { HStack, Text, Button, useColorModeValue, } from '@chakra-ui/react';
import { IoCalculator } from 'react-icons/io5';
import { fromNow, sort, startOf } from '@trifence/utilities';
import { TransactionItem } from './TransactionItem';
import { InfiniteScrollList, } from '../InfiniteScrollList';
export var TransactionList = memo(function (props) {
    var transactions = props.transactions, businessId = props.businessId, perspective = props.perspective, locale = props.locale, placeholderAsset = props.placeholderAsset, cancelAsset = props.cancelAsset, dailyButtonProps = props.dailyButtonProps, dailyButtonFunction = props.dailyButtonFunction, children = props.children, rest = __rest(props, ["transactions", "businessId", "perspective", "locale", "placeholderAsset", "cancelAsset", "dailyButtonProps", "dailyButtonFunction", "children"]);
    var alternateColor = useColorModeValue('gray.100', 'gray.900');
    var items = useMemo(function () {
        /* Sort all transactions descendingly */
        var sortedTransactions = sort(function (transaction1, transaction2) {
            return transaction2.createdAt - transaction1.createdAt;
        })(transactions);
        /* Group transactions by day */
        var transactionSections = sortedTransactions.reduce(function (sectionsSoFar, transaction) {
            var _a;
            var _b;
            var createdAt = transaction.createdAt;
            var sectionKey = startOf('day')(createdAt);
            var sectionTransactions = (_b = sectionsSoFar[sectionKey]) !== null && _b !== void 0 ? _b : [];
            return __assign(__assign({}, sectionsSoFar), (_a = {}, _a[sectionKey] = sectionTransactions.concat(transaction), _a));
        }, {});
        /* Flatten transaction sections */
        var transactionRows = Object.entries(transactionSections)
            .map(function (_a) {
            var sectionKey = _a[0], sectionTransactions = _a[1];
            return __spreadArray([Number(sectionKey)], sectionTransactions, true);
        })
            .flat();
        return transactionRows;
    }, [transactions]);
    if (transactions.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        children,
        React.createElement(InfiniteScrollList, __assign({ spacing: 2 }, rest), items.map(function (item, index) {
            if (typeof item === 'number') {
                return (React.createElement(HStack, { key: item }, perspective === 'BUSINESS' ? (React.createElement(Button, __assign({ colorScheme: "brand", rightIcon: React.createElement(IoCalculator, null), onClick: function () {
                        dailyButtonFunction === null || dailyButtonFunction === void 0 ? void 0 : dailyButtonFunction(item);
                    } }, dailyButtonProps), fromNow({ locale: locale })(item))) : (React.createElement(Text, { fontSize: "lg" }, fromNow({ locale: locale })(item)))));
            }
            return (React.createElement(TransactionItem, { cancelAsset: cancelAsset, placeholderAsset: placeholderAsset, backgroundColor: index % 2 !== 0 ? alternateColor : undefined, transaction: item, perspective: perspective, locale: locale, key: item._id }));
        }))));
});
TransactionList.displayName = 'TransactionList';
