import { gql } from '@apollo/client';

export const BUSINESS_DATA_FRAGMENT = gql`
  fragment BusinessData on Business {
    _id
    name
    logoUrl
    photoUrl
    websiteUrl
    isComingSoon
    address {
      street
      postalCode
      city
    }
  }
`;
