import { __assign, __rest } from "tslib";
import React from 'react';
import { Stack, HStack, Text, Image, useBreakpoint, Box, } from '@chakra-ui/react';
import { formatTimestamp } from '@trifence/utilities';
import { MoneyTag } from '../MoneyTag';
import { useTranslation } from 'react-i18next';
import { CircledPlusIcon, CircledMinusIcon } from '..';
export function TransactionItem(props) {
    var _a, _b, _c, _d;
    var transaction = props.transaction, perspective = props.perspective, locale = props.locale, placeholderAsset = props.placeholderAsset, cancelAsset = props.cancelAsset, rest = __rest(props, ["transaction", "perspective", "locale", "placeholderAsset", "cancelAsset"]);
    var t = useTranslation('card').t;
    var size = useBreakpoint();
    var cardIsSpecial = (_b = (_a = transaction === null || transaction === void 0 ? void 0 : transaction.cardId) === null || _a === void 0 ? void 0 : _a.startsWith) === null || _b === void 0 ? void 0 : _b.call(_a, '00000000000');
    var cardDirectionIcon = (transaction === null || transaction === void 0 ? void 0 : transaction.amount) > 0 ? (React.createElement(CircledPlusIcon, { marginLeft: "0.5em", marginBottom: "0.5em", height: "1em" })) : (React.createElement(CircledMinusIcon, { marginLeft: "0.5em", marginBottom: "0.5em", height: "1em" }));
    var cardDescriptionForBusiness = cardIsSpecial ? (t(transaction.amount > 0
        ? 'transactions.bankIncoming'
        : 'transactions.bankOutgoing')) : (React.createElement(React.Fragment, null,
        ((_c = transaction === null || transaction === void 0 ? void 0 : transaction.card) === null || _c === void 0 ? void 0 : _c.serial)
            ? t(transaction.card.serial >= 90000
                ? 'transactions.testCardNumber'
                : 'transactions.cardNumber') + transaction.card.serial
            : t('transactions.card'),
        cardDirectionIcon));
    return (React.createElement(HStack, __assign({ bgImage: transaction.isCancelled ? cancelAsset : undefined, bgRepeat: "repeat", justify: "space-between", padding: 2, key: transaction._id }, rest),
        React.createElement(HStack, { maxWidth: "60%", opacity: transaction.isCancelled ? 0.4 : 1 },
            React.createElement(Image, { margin: 2, boxSize: { base: 8, sm: 10 }, objectFit: "cover", rounded: "full", src: perspective === 'BUSINESS'
                    ? transaction.card.designUrl
                    : transaction.business.logoUrl, fallbackSrc: placeholderAsset, alt: transaction.business.name, filter: transaction.isCancelled ? 'grayscale(1)' : undefined }),
            React.createElement(Stack, { spacing: 0 },
                React.createElement(Text, { noOfLines: 1, wordBreak: "break-all" },
                    perspective === 'BUSINESS'
                        ? cardDescriptionForBusiness
                        : transaction.business.name,
                    transaction.isCancelled
                        ? ' • ' + t('transactionCancelled.label')
                        : undefined),
                React.createElement(HStack, null,
                    ((_d = transaction.device) === null || _d === void 0 ? void 0 : _d.color) ? (React.createElement(Box, { boxSize: "0.7em", backgroundColor: transaction.device.color, rounded: "full" })) : null,
                    React.createElement(Text, { noOfLines: 1, fontSize: "xs", opacity: 0.7 }, formatTimestamp({
                        format: size === 'base' ? 'LT' : 'LLL',
                        locale: locale,
                    })(transaction.createdAt))))),
        React.createElement(MoneyTag, { amount: transaction.amount, perspective: perspective, isCancelled: transaction.isCancelled })));
}
