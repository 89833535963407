import React, { useEffect } from 'react';
import { useQueryParams, useStorage } from '@companyon/hooks';
import { useHistory } from 'react-router-dom';
function maybeBoolFromQueryString(query, key) {
    if (!query.has(key)) {
        return undefined;
    }
    var value = query.get(key);
    return [null, '', '1', 'true', 'on', 'yes'].includes(value);
}
export function Configuration() {
    var history = useHistory();
    var query = useQueryParams();
    var _a = useStorage(), _b = _a[0], setDemoMode = _a[1].setDemoMode;
    var enableDemoMode = maybeBoolFromQueryString(query, 'enableDemoMode');
    if (enableDemoMode !== undefined) {
        setDemoMode(enableDemoMode);
        useEffect(function () {
            history.push('/');
        });
    }
    var clearConfiguration = maybeBoolFromQueryString(query, 'reset');
    if (clearConfiguration !== undefined) {
        useEffect(function () {
            localStorage.clear();
            window.location.href = '/';
        });
    }
    // Maybe have a config page some time…
    return React.createElement("div", null, "Configuration applied.");
}
