import {
  Box,
  HStack,
  useBreakpointValue,
  Image,
  Flex,
  Link,
  Spacer,
  StackProps,
  keyframes,
} from '@chakra-ui/react';
import { ReactElement, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { colors } from '@companyon/constants';
import companyonButton from '../assets/images/companyon-button.svg';
import regionButton from '../assets/images/region-button.svg';
import legalButton from '../assets/images/legal-button.svg';
import trifenceButton from '../assets/images/trifence-button.svg';

type LogoBoxProps = StackProps & {
  icon: string;
  line1: string | ReactElement;
  line2: string | ReactElement;
  hideText?: boolean;
  imageSize?: string;
};

const vOuterAmination = keyframes`
0% { transform: rotate(-90deg); }
50% { transform: rotate(-45deg); }
100% { transform: rotate(0deg); }
`;

const vInnerAmination = keyframes`
0% { transform: rotate(90deg); background-color: transparent; }
50% { transform: rotate(45deg); background-color: transparent; }
100% { transform: rotate(0deg); background-color: ${colors.brand[200]}; }
`;

const vTextAnimation = keyframes`
0% { opacity: 0%; }
40% { opacity: 0%; }
80% { opacity: 100%; }
`;

function LogoBox(props: LogoBoxProps) {
  const { icon, line1, line2, hideText, imageSize = '100%', ...rest } = props;
  return (
    <HStack height="100%" {...rest}>
      <Image
        src={icon}
        height={imageSize}
        width={imageSize}
        maxWidth={imageSize}
      />
      {hideText ? undefined : (
        <Flex
          direction="column"
          align="left"
          paddingLeft="0.5em"
          animation={
            hideText === false ? `${vTextAnimation} 0.5s linear` : undefined
          }
        >
          <Box isTruncated>{line1}</Box>
          <Box isTruncated>{line2}</Box>
        </Flex>
      )}
    </HStack>
  );
}

function VLogoBox(props: LogoBoxProps) {
  const { hideText } = props;
  const bgProps = hideText
    ? undefined
    : {
        animation: `${vInnerAmination} 0.5s linear`,
        backgroundColor: 'brand.200',
      };
  return (
    <LogoBox
      transformOrigin="2em 2em"
      marginTop="1em"
      height="4em"
      imageSize="4em"
      borderTopLeftRadius="full"
      borderBottomLeftRadius="full"
      {...bgProps}
      {...props}
    />
  );
}

export function Footer() {
  const { t } = useTranslation('landing');
  const [vertFooterOpen, setVertFooterOpen] = useState(false);
  const horizontal = useBreakpointValue({ base: false, xl: true });
  const horizHeight = useBreakpointValue({ base: '3em', '2xl': '4em' });
  const horizFontSize = useBreakpointValue({ base: 'md', '2xl': 'xl' });
  const vertFontSize = useBreakpointValue({ base: 'sm', sm: 'xl', md: '2xl' });
  const agbText = useBreakpointValue({
    base: t('footer.legal1_short'),
    sm: t('footer.legal1_long'),
  });

  const texts = [
    {
      icon: regionButton,
      line1: t('footer.region1'),
      line2: t('footer.region2'),
    },
    {
      icon: companyonButton,
      line1: t('footer.companyon1'),
      line2: t('footer.companyon2'),
    },
    {
      icon: trifenceButton,
      line1: t('footer.trifence1'),
      line2: (
        <Link href="https://trifence.ch" isExternal target="_blank">
          {t('footer.trifence2')}
        </Link>
      ),
    },
    {
      icon: legalButton,
      line1: (
        <Link as={RouterLink} to="/agb">
          {agbText}
        </Link>
      ),
      line2: (
        <Link as={RouterLink} to="/privacy">
          {t('footer.legal2')}
        </Link>
      ),
    },
  ];

  // "UBS bars" laid out horizontally
  if (horizontal) {
    return (
      <Box paddingLeft={4} paddingY={4} marginTop="auto">
        <Flex
          as="footer"
          role="contentinfo"
          backgroundColor="brand.200"
          borderTopLeftRadius="full"
          borderBottomLeftRadius="full"
          height={horizHeight}
          fontSize={horizFontSize}
        >
          <LogoBox {...texts[0]} />
          <Spacer maxWidth="4em" />
          <LogoBox {...texts[1]} />
          <Spacer maxWidth="4em" />
          <LogoBox {...texts[2]} />
          <Spacer maxWidth="4em" />
          <LogoBox {...texts[3]} />
          <Spacer />
        </Flex>
      </Box>
    );
  } else {
    // "Vertical" footer
    // - Round buttons collapsed horizontally first
    // - On click, horizontal "UBS bars" stacked on top of each other
    return (
      <Box paddingLeft={4} paddingY={4} marginTop="auto">
        <Flex
          overflow="hidden"
          as="footer"
          role="contentinfo"
          transformOrigin="2em 2em"
          animation={
            vertFooterOpen ? `${vOuterAmination} 0.5s linear` : undefined
          }
          direction={vertFooterOpen ? 'column' : 'row'}
          minHeight="4em"
          fontSize={vertFontSize}
          onClick={() => {
            setVertFooterOpen(!vertFooterOpen);
            setTimeout(() => {
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
              });
            });
          }}
        >
          <Spacer maxWidth="1em" />
          <VLogoBox hideText={!vertFooterOpen} {...texts[0]} />
          <Spacer maxWidth="1em" marginX={2} height="1em" />
          <VLogoBox hideText={!vertFooterOpen} {...texts[1]} />
          <Spacer maxWidth="1em" marginX={2} height="1em" />
          <VLogoBox hideText={!vertFooterOpen} {...texts[2]} />
          <Spacer maxWidth="1em" marginX={2} height="1em" />
          <VLogoBox hideText={!vertFooterOpen} {...texts[3]} />
          <Box borderBottomWidth="1em" borderColor="transparent" />
          <Spacer maxWidth="1em" marginX={2} height="1em" />
        </Flex>
      </Box>
    );
  }
}
