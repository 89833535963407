import {
  Flex,
  Stack,
  Heading,
  Text,
  Button,
  FlexProps,
  useColorModeValue,
  Box,
  useBreakpointValue,
  Center,
  LightMode,
  ThemeTypings,
  BoxProps,
} from '@chakra-ui/react';
import { IoIosFlash } from 'react-icons/io';
import { RiQrCodeLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnimatedCard } from '../components/AnimatedCard';
import { ReactChild, ReactElement } from 'react';
import givingAsset from '../assets/images/giving.jpeg';
import happyAsset from '../assets/images/happy.jpeg';
import trustAsset from '../assets/images/trust.jpeg';
import localAsset from '../assets/images/local.jpeg';
import luckAsset from '../assets/images/luck.jpeg';
import TextLoop from 'react-text-loop';
import { AiFillShop } from 'react-icons/ai';
import { useStorage } from '@companyon/hooks';

export type Branding = {
  // Top title, can be graphic
  brandLogo: ReactElement;
  // Whether to split after the logo (undefined=auto, i.e., let the code here decide)
  splitAfterLogo?: boolean;
  // key for "CopmanyON-Karte"
  brandKey: string;
  // key for "CopmanyON-Karte Ihrer Region"
  regionalBrandKey: string;
};

type JointHeroProps = {
  branding: Branding;
};

type HeroProps = JointHeroProps & {
  children: ReactChild;
  image: any;
  overlay: ThemeTypings['colors'];
  maxWidth?: BoxProps['maxWidth'];
};

type MainHeroProps = JointHeroProps & FlexProps;

export function Hero(props: HeroProps) {
  const { children, image, overlay, maxWidth } = props;
  return (
    <Box
      as="section"
      bg="gray.800"
      py="12"
      position="relative"
      h={{
        base: '560px',
        sm: '28em',
        md: '40em',
        lg: '56em',
        xl: '70em',
        '2xl': '88em',
      }}
      bgImage={image}
      bgColor=""
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        bg: overlay,
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >
      <Box
        maxWidth={maxWidth}
        mx="auto"
        px={{ base: '0', sm: '4', md: '8' }}
        h="full"
        zIndex={1}
        position="relative"
      >
        {children}
      </Box>
    </Box>
  );
}

export function HeroTop(props: MainHeroProps) {
  const { branding, ...rest } = props;
  const { t } = useTranslation(['landing', 'info']);
  const [{ demoMode }] = useStorage();
  const primaryButtonBorderColor = useColorModeValue('white', 'brand');
  const splitHeading = useBreakpointValue({ base: true, '3xl': false });
  const button1Key =
    useBreakpointValue({ base: 'what_short', sm: 'what' }) ?? 'what';
  const replacements = {
    brand: t(branding.brandKey),
    regionalBrand: t(branding.regionalBrandKey),
  };

  return (
    <Hero branding={branding} image={givingAsset} overlay="whiteAlpha.600">
      <Flex
        flex={1}
        direction={{ base: 'column', xl: 'row-reverse' }}
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        height="full"
        align="center"
        paddingX={{ base: 0, sm: 8, md: 18 }}
        paddingY={{ base: 4, md: 12, lg: 16 }}
        zIndex={2}
        {...rest}
      >
        <AnimatedCard
          width={{
            base: '50%',
            sm: '30%',
            md: '40%',
            lg: '40%',
            xl: '50%',
            '2xl': '40%',
          }}
          marginTop={{ base: 0, md: 0 }}
          marginBottom={{ base: 4, md: 0 }}
          marginX={8}
        />

        <Stack
          width={{ base: '100%', md: '90%', '2xl': '60%' }}
          align="center"
          spacing={{ base: 3, md: 12 }}
        >
          <Heading
            as="h1"
            fontSize={{
              base: '3xl',
              sm: '4xl',
              md: '6xl',
              lg: '7xl',
              xl: '6xl',
            }}
            lineHeight={1.2}
            textAlign="center"
          >
            {branding.brandLogo}
            {branding.splitAfterLogo ?? (splitHeading ? <br /> : ' ')}
            {t('heading.start')}
            {demoMode ? (
              t('heading.1')
            ) : (
              <>
                <TextLoop
                  springConfig={{ stiffness: 180, damping: 8 }}
                  children={'0123'
                    .split('')
                    .map(
                      (i) => (t('landing:heading.' + i) as string | null) ?? '',
                    )}
                />
                <br />
              </>
            )}
            {t('heading.end')}
          </Heading>

          <Stack
            direction={{ base: 'column', md: 'row' }}
            width={{ base: '80%', md: 'auto' }}
          >
            <Button
              as={Link}
              to="/info"
              variant="outline"
              size="lg"
              colorScheme="brand"
              backgroundColor={primaryButtonBorderColor}
              borderWidth={2}
              width={{ base: '100%', md: 'auto' }}
            >
              {t(button1Key, replacements)}
            </Button>
            <Button
              as={Link}
              to="/join"
              variant="outline"
              size="lg"
              colorScheme="brand"
              backgroundColor={primaryButtonBorderColor}
              borderWidth={2}
              width={{ base: '100%', md: 'auto' }}
            >
              {t('who')}
            </Button>

            <Button
              as={Link}
              to="/scan"
              size="lg"
              colorScheme="brand"
              borderWidth={0}
              leftIcon={<RiQrCodeLine />}
              width={{ base: '100%', md: 'auto' }}
            >
              {t('scanCard')}
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </Hero>
  );
}

type HeroBottomProps = JointHeroProps & {
  imageAsset: string;
  overlay: ThemeTypings['colors'];
  color: ThemeTypings['colors'];
  maxWidth?: BoxProps['maxWidth'];
  headingKey: string;
  headingSize?: any;
  subHeadingKey: string;
  bodyKey1: string;
  bodyKey2?: string;
  button2Link?: string;
  button2LeftIcon?: ReactElement;
  button2ShortKey?: string;
  button2LongKey?: string;
};

export function HeroBottom(props: HeroBottomProps) {
  const {
    imageAsset,
    overlay,
    color,
    maxWidth,
    headingKey,
    headingSize,
    subHeadingKey,
    bodyKey1,
    bodyKey2,
    button2Link,
    button2LeftIcon,
    button2ShortKey,
    button2LongKey,
    branding,
  } = props;
  const moreIncluded = useBreakpointValue({ base: false, lg: true });
  const defaultHeadingSize = useBreakpointValue({
    base: '3xl',
    sm: '4xl',
  });
  const { t } = useTranslation(['landing', 'info']);
  const replacements = {
    brand: t(branding.brandKey),
    regionalBrand: t(branding.regionalBrandKey),
  };

  return (
    <Hero
      image={imageAsset}
      overlay={overlay}
      maxWidth={maxWidth ?? { base: 'xl', md: '5xl' }}
      branding={branding}
    >
      <Center flexDirection="column" textAlign="center" color={color} h="full">
        <Heading size={headingSize ?? defaultHeadingSize}>
          {t(headingKey)}
        </Heading>
        <Heading as="h3" size="2xl" mt={4}>
          {t(subHeadingKey)}
        </Heading>
        {moreIncluded ? (
          <>
            <Text fontSize="3xl" mt={8}>
              {t(bodyKey1, replacements)}
            </Text>
            {bodyKey2 ? (
              <Text fontSize="3xl" mt={8}>
                {t(bodyKey2, replacements)}
              </Text>
            ) : undefined}
          </>
        ) : undefined}
        <LightMode>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            width={{ base: '80%', md: 'auto' }}
            mt={8}
          >
            {moreIncluded ? undefined : (
              <Button
                as={Link}
                to="/info"
                variant="outline"
                size="lg"
                colorScheme="brand"
                backgroundColor="white"
                borderWidth={2}
              >
                {t('more')}
              </Button>
            )}
            <Button
              as={Link}
              to={button2Link ?? '/shops'}
              colorScheme="brand"
              size="lg"
              fontWeight="bold"
              leftIcon={button2LeftIcon ?? <AiFillShop />}
            >
              {t(
                moreIncluded
                  ? button2LongKey ?? 'buy_long'
                  : button2ShortKey ?? 'buy_short',
                replacements,
              )}
            </Button>
          </Stack>
        </LightMode>
      </Center>
    </Hero>
  );
}

export function Hero1(props: JointHeroProps) {
  const { branding } = props;
  return (
    <HeroBottom
      branding={branding}
      imageAsset={happyAsset}
      overlay="blackAlpha.700"
      color="white"
      headingKey="info:iterate.1._"
      subHeadingKey="info:iterate.1.__"
      bodyKey1="info:iterate.1.a"
      bodyKey2="info:iterate.1.b"
    />
  );
}

export function Hero2(props: JointHeroProps) {
  const { branding } = props;
  return (
    <HeroBottom
      branding={branding}
      imageAsset={trustAsset}
      overlay="whiteAlpha.700"
      color="black"
      headingKey="info:iterate.2._"
      subHeadingKey="info:iterate.2.__"
      bodyKey1="info:iterate.2.a"
    />
  );
}

export function Hero3(props: JointHeroProps) {
  const { branding } = props;
  return (
    <HeroBottom
      branding={branding}
      imageAsset={luckAsset}
      overlay="blackAlpha.600"
      color="white"
      headingKey="info:iterate.3._"
      subHeadingKey="info:iterate.3.__"
      bodyKey1="info:iterate.3.a"
      bodyKey2="info:iterate.3.b"
      button2Link="/offers"
      button2LeftIcon={<IoIosFlash />}
      button2ShortKey="offers_short"
      button2LongKey="offers_long"
    />
  );
}

export function Hero4(props: JointHeroProps) {
  const { branding } = props;
  return (
    <HeroBottom
      branding={branding}
      imageAsset={localAsset}
      overlay="whiteAlpha.700"
      color="black"
      headingKey="info:iterate.4._"
      maxWidth={{ base: 'xl', md: '6xl' }}
      subHeadingKey="info:iterate.4.__"
      bodyKey1="info:iterate.4.a"
    />
  );
}
