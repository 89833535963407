import { FlexProps } from '@chakra-ui/react';
import { FAB } from '@companyon/components';
import { RiQrCodeLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import {
  Branding,
  Hero1,
  Hero2,
  Hero3,
  Hero4,
  HeroTop,
} from '../components/Hero';

type Props = FlexProps & { branding?: Branding };

export function Landing(props: Props) {
  const {
    branding = {
      brandLogo: <span>CompanyON —</span>,
      brandKey: 'info:companyonBrand',
      regionalBrandKey: 'info:companyonRegionalBrand',
    },
    children,
    ...rest
  } = props;
  const history = useHistory();

  function handleScanClick() {
    history.push('/scan');
  }

  return (
    <>
      <HeroTop branding={branding} {...rest} />
      <Hero1 branding={branding} {...rest} />
      <Hero2 branding={branding} {...rest} />
      <Hero3 branding={branding} {...rest} />
      <Hero4 branding={branding} {...rest} />
      {children}
      <FAB
        colorScheme="brand"
        aria-label="Scan card"
        icon={<RiQrCodeLine size={30} />}
        onClick={handleScanClick}
      />
    </>
  );
}
