import {
  Stack,
  Heading,
  Button,
  Box,
  Text,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RiQrCodeLine } from 'react-icons/ri';
import { BusinessItem } from '../components/BusinessGrid';
import steinAsset from '../assets/images/steinamrhein.jpeg';
import companyonAsset from '../assets/images/companyon.svg';
import trifenceAsset from '../assets/images/trifence.svg';
import { Helmet } from 'react-helmet';

function gvstar(str: string) {
  const gvstar = /^(.*)<gvstar>(.*)<\/gvstar>(.*)$/.exec(str);
  if (gvstar) {
    const [, left, inside, right] = gvstar;
    return (
      <>
        {left}
        <a href="https://gewerbe-star.ch">
          <Text color="brand.700" as="span">
            {inside}
          </Text>
        </a>
        {right}
      </>
    );
  } else {
    return <Text>{str}</Text>;
  }
}

export function Join() {
  const { t } = useTranslation(['join', 'landing']);
  const showIcon = useBreakpointValue({ base: false, sm: true });

  return (
    <Stack
      maxWidth="5xl"
      alignSelf="center"
      marginX="auto"
      paddingX={{ base: 2, sm: 4, lg: 8 }}
      spacing={3}
    >
      <Helmet>
        <title>CompanyON: {t('heading')}</title>
      </Helmet>
      <Heading
        as="h1"
        color="brand.700"
        textAlign="left"
        fontSize={{ base: '4xl', sm: '5xl', md: '6xl' }}
      >
        {t('heading')}
      </Heading>

      <SimpleGrid
        minChildWidth={{ base: '16em', sm: '20em', lg: '24em' }}
        spacing={4}
      >
        <BusinessItem
          backgroundColor="brand.100"
          imageHeight="sm"
          business={{
            websiteUrl: 'https://gutstein.ch',
            photoUrl: steinAsset,
            logoUrl: showIcon
              ? 'https://uploads.companyon.ch/api/file/gvstar-logo.png'
              : undefined,
            name: t('iterate.1._'),
            address: { street: t('iterate.1.__') },
          }}
        >
          <Box padding={4}>
            <Text>{t('iterate.1.a')}</Text>
            <Heading paddingTop={2}>{t('iterate.1.__2')}</Heading>
            <Text>{gvstar(t('iterate.1.b'))}</Text>
          </Box>
        </BusinessItem>

        <BusinessItem
          backgroundColor="brand.100"
          imageHeight="sm"
          imageFit="contain"
          business={{
            photoUrl: companyonAsset,
            logoUrl: showIcon ? trifenceAsset : undefined,
            name: t('iterate.2._'),
            address: { street: t('iterate.2.__') },
          }}
        >
          <Box padding={4}>
            <Text>{t('iterate.2.a')}</Text>
            <Text paddingTop={2}>{t('iterate.2.b')}</Text>
            <Heading paddingTop={2}>{t('iterate.3._')}</Heading>
            <Text>
              {t('iterate.3.__')}
              <br />
              {t('iterate.3.a')}
              <br />
              {t('iterate.3.b')}
              <br />
              {t('iterate.3.c')}
              <br />
              {t('iterate.3.d')}
            </Text>
          </Box>
        </BusinessItem>
      </SimpleGrid>

      <Stack
        paddingX={{ base: undefined, md: 'auto' }}
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 2, md: 'auto' }}
        paddingTop={4}
        paddingBottom={8}
      >
        <Button
          as={Link}
          to="/info"
          variant="outline"
          size="lg"
          colorScheme="brand"
          backgroundColor="white"
          borderWidth={2}
        >
          {t('landing:what', { brand: 'CompanyON' })}
        </Button>
        <Button
          as={Link}
          to="/shops"
          variant="outline"
          size="lg"
          colorScheme="brand"
          backgroundColor="white"
          borderWidth={2}
        >
          {t('landing:shops')}
        </Button>

        <Button
          as={Link}
          to="/scan"
          size="lg"
          colorScheme="brand"
          leftIcon={<RiQrCodeLine />}
        >
          {t('landing:scanCard')}
        </Button>
      </Stack>
    </Stack>
  );
}
