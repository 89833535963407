import { Stack, Heading, Button, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SpecialOffers } from '../components/SpecialOffers';
import { RiQrCodeLine } from 'react-icons/ri';
import { Helmet } from 'react-helmet';

export function Offers() {
  const { t } = useTranslation(['offers', 'landing']);

  return (
    <Stack
      maxWidth="5xl"
      alignSelf="center"
      marginX="auto"
      paddingX={{ base: 2, sm: 4, lg: 8 }}
      spacing={3}
    >
      <Helmet>
        <title>CompanyON: {t('heading')}</title>
      </Helmet>
      <Heading
        as="h1"
        color="brand.700"
        textAlign="left"
        fontSize={{ base: '4xl', sm: '5xl', md: '6xl' }}
      >
        {t('heading')}
      </Heading>
      <Text>{t('explanation')}</Text>
      <SpecialOffers />

      <Stack
        paddingX={{ base: undefined, md: 'auto' }}
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 2, md: 'auto' }}
        paddingTop={4}
        paddingBottom={8}
      >
        <Button
          as={Link}
          to="/join"
          variant="outline"
          size="lg"
          colorScheme="brand"
          backgroundColor="white"
          borderWidth={2}
        >
          {t('landing:who')}
        </Button>
        <Button
          as={Link}
          to="/shops"
          variant="outline"
          size="lg"
          colorScheme="brand"
          backgroundColor="white"
          borderWidth={2}
        >
          {t('landing:shops')}
        </Button>

        <Button
          as={Link}
          to="/scan"
          size="lg"
          colorScheme="brand"
          borderWidth={2}
          leftIcon={<RiQrCodeLine />}
        >
          {t('landing:scanCard')}
        </Button>
      </Stack>
    </Stack>
  );
}
