import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { maybeRecoverIds } from '@trifence/utilities';

if (maybeRecoverIds()) {
  window.location.replace('/');
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
