import { __assign, __rest } from "tslib";
import React from 'react';
import { Box, Flex, Spacer, useBreakpointValue, } from '@chakra-ui/react';
import { IoIosCloseCircle } from 'react-icons/io';
import { IoMenuOutline } from 'react-icons/io5';
export function NavigationBar(props) {
    var children = props.children, logo = props.logo, switcher = props.switcher, isOpen = props.isOpen, onToggle = props.onToggle, onClose = props.onClose, rest = __rest(props, ["children", "logo", "switcher", "isOpen", "onToggle", "onClose"]);
    var isClosed = useBreakpointValue({ base: false, md: true });
    // Catch moving to wider screen while menu is open
    // (otherwise, results e.g. in lack of visible button border)
    if (isClosed && isOpen) {
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    return (React.createElement(Flex, __assign({ as: "nav", width: "100%", justify: "space-between", align: "center", wrap: "wrap", paddingX: 8, paddingY: 6 }, rest),
        logo,
        React.createElement(Spacer, null),
        switcher,
        React.createElement(Box, { display: { base: 'block', md: 'none' }, onClick: onToggle }, isOpen ? React.createElement(IoIosCloseCircle, { size: 24 }) : React.createElement(IoMenuOutline, { size: 24 })),
        React.createElement(Box, { display: { base: isOpen ? 'block' : 'none', md: 'block' }, flexBasis: { base: '100%', md: 'auto' } },
            React.createElement(Flex, { wrap: "wrap", justify: ['flex-end', 'flex-end', 'flex-end', 'flex-end'], align: ['end', 'end', 'center', 'center'], spacing: [2, 2, 4, 4] }, children))));
}
