import { __assign, __rest } from "tslib";
import React from 'react';
import { Button, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup, } from '@chakra-ui/react';
import { useStorage } from '@companyon/hooks/dist';
import { MdLanguage } from 'react-icons/md';
import { possibleLngs } from '@companyon/constants/dist';
export function LanguageSwitcher(props) {
    var menuTextColor = props.menuTextColor, onMenuClose = props.onMenuClose, regularButtonProps = __rest(props, ["menuTextColor", "onMenuClose"]);
    var _a = useStorage(), language = _a[0].language, setLanguage = _a[1].setLanguage;
    function onLanguageSelection(language) {
        onMenuClose();
        setLanguage(language);
    }
    return (React.createElement(Menu, null,
        React.createElement(MenuButton, __assign({ as: Button, textTransform: "uppercase", leftIcon: React.createElement(MdLanguage, null) }, regularButtonProps), language),
        React.createElement(MenuList, { color: menuTextColor },
            React.createElement(MenuOptionGroup, { type: "radio", defaultValue: language, onChange: onLanguageSelection }, Object.entries(possibleLngs).map(function (_a) {
                var k = _a[0], v = _a[1];
                return (React.createElement(MenuItemOption, { value: k, key: k }, v));
            })))));
}
