import { keyframes } from '@chakra-ui/react';
import { MoneyTag, MoneyTagProps } from '@companyon/components';

const flyIn = keyframes`
0% { transform: scale(8); filter: blur(5px) opacity(0%); }
20% { filter: blur(0) opacity(100%); }
40% { transform: scale(0.8); }
60% { transform: scale(1.5); }
80% { transform: scale(1.1); }
100% { transform: scale(1); }
`;

const flyIn2 = keyframes`
0% { filter: opacity(0%); }
30% { transform: scale(8); filter: blur(5px) opacity(0%); }
40% { filter: blur(0) opacity(100%); }
50% { transform: scale(0.8); }
60% { transform: scale(1.5); }
70% { transform: scale(1.1); }
80% { transform: scale(1); }
`;

export const flyInAnimation = `${flyIn} 1s linear`;
// Same speed, but delayed by 0.6s (and done by 1.6s; just for the ease of the math)
export const flyInAnimation2 = `${flyIn2} 2s linear`;

export function FlyInMoneyTag(props: MoneyTagProps) {
  return <MoneyTag animation={flyInAnimation} {...props} />;
}
