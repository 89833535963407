import { useState, useMemo, ReactElement, useEffect } from 'react';
import {
  Box,
  Stack,
  VStack,
  Heading,
  Image,
  Text,
  Badge,
  Button,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  Skeleton,
  useDisclosure,
  useToast,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Link as ChakraLink,
  useBreakpointValue,
  HStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
  Alerter,
  ButtonList,
  BackToTop,
  TransactionList,
  MoneyTag,
  UTag,
  CenteredSpinner,
} from '@companyon/components';
import {
  IoBagHandle,
  IoCloudUploadOutline,
  IoPerson,
  IoStopwatchOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import { BiUnlink, BiLink } from 'react-icons/bi';
import { parseVCards, VCard4 } from 'vcard4-ts';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { match, nowMillis } from '@trifence/utilities';
import { urls, pagination, times } from '@companyon/constants';
import { AnimatedCard } from '../components/AnimatedCard';
import { VideoUploadModal } from '../components/VideoUploadModal';
import { useStorage } from '@companyon/hooks';
import { useGetCardQuery } from '../graphql/hooks/queries/getCard';
import { useRegisterDeviceMutation } from '../graphql/hooks/mutations/registerDevice';
import { useActivateDeviceMutation } from '../graphql/hooks/mutations/activateDevice';
import { useLinkCardMutation } from '../graphql/hooks/mutations/linkCard';
import { useUnlinkCardMutation } from '../graphql/hooks/mutations/unlinkCard';
import { useSetCardAttachmentMutation } from '../graphql/hooks/mutations/setCardAttachment';
import { useDeleteCardAttachmentMutation } from '../graphql/hooks/mutations/deleteCardAttachment';
import { Businesses } from './Businesses';
import { SpecialOffers } from '../components/SpecialOffers';
import cancelAsset from '../assets/images/cancel.svg';
import placeholderAsset from '../assets/images/placeholder.png';
import { IconTab } from '../components/IconTab';
import { AiFillShop, AiOutlineDownload } from 'react-icons/ai';
import { FaInfoCircle } from 'react-icons/fa';
import { TiBusinessCard } from 'react-icons/ti';
import { GrTransaction } from 'react-icons/gr';
import { IoIosFlash, IoIosLock, IoIosVideocam } from 'react-icons/io';
import {
  flyInAnimation,
  flyInAnimation2,
  FlyInMoneyTag,
} from '../components/FlyInMoneyTag';
import { Helmet } from 'react-helmet';
import { MdBrokenImage } from 'react-icons/md';

type TabElement = {
  label: string;
  panel: ReactElement;
  leftIcon: ReactElement;
  isNew?: boolean;
  small?: boolean;
  hidden?: boolean;
};

type RouteParams = {
  cardId: string;
};

type ExpiryProps = {
  expiresInWeeks: number;
};

function attachmentByType(attachments: any, type: string) {
  if (Array.isArray(attachments)) {
    for (const a of attachments) {
      if (a?.type === type) {
        return a;
      }
    }
  }
  return null;
}

export function Card() {
  const { cardId } = useParams<RouteParams>();
  const [{ deviceId }, { pickDeviceId }] = useStorage();
  const { t, i18n } = useTranslation('card');
  const addToast = useToast();
  const videoUploadModal = useDisclosure();
  const videoDeletionDialog = useDisclosure();
  const videoExpiryDialog = useDisclosure();
  const hideTabLabel = useBreakpointValue({ base: true, md: false });
  const [tabIndex, setTabIndex] = useState(hideTabLabel === false ? 1 : 0);
  const [vcardActivated, setVcardActivated] = useState(false);

  const getCardQuery = useGetCardQuery({
    variables: {
      _id: cardId,
      transactionsSkip: 0,
      transactionsLimit: pagination.PAGE_SIZE,
    },
  });

  const [registerDevice, registerDeviceMutation] = useRegisterDeviceMutation();
  const [activateDevice, activateDeviceMutation] = useActivateDeviceMutation();

  const [linkCard, linkCardMutation] = useLinkCardMutation({
    onCompleted: (data: any) => {
      if (data.linkCard) {
        addToast({
          status: 'success',
          title: t('toast.cardLinked'),
        });
      }
    },
  });

  const [unlinkCard, unlinkCardMutation] = useUnlinkCardMutation({
    onCompleted: (data: any) => {
      if (data.unlinkCard) {
        addToast({
          status: 'success',
          title: t('toast.cardUnlinked'),
        });
      }
    },
  });

  const [setCardAttachment, setCardAttachmentMutation] =
    useSetCardAttachmentMutation({
      onCompleted: (data: any) => {
        if (data?.setCardAttachment) {
          addToast({
            status: 'success',
            title: t('toast.videoUpdated'),
          });
        }
      },
    });

  const [deleteCardAttachment, deleteCardAttachmentMutation] =
    useDeleteCardAttachmentMutation({
      onCompleted: (data: any) => {
        if (data.deleteCardAttachment) {
          addToast({
            status: 'success',
            title: t('toast.videoDeleted'),
          });
        }
      },
    });

  const uploadsUrl = useMemo(() => {
    return match(process.env.REACT_APP_ENV)
      .with('staging', () => urls.uploads.staging)
      .with('production', () => urls.uploads.production)
      .otherwise(() => urls.uploads.development);
  }, []);

  const {
    balance,
    transactions,
    transactionsCount,
    hasCancelledTransactions,
    designUrl,
    isLinked,
    isLinkedToViewer,
    attachments,
  } = {
    balance: getCardQuery.data?.card.balance ?? 0,
    transactions: getCardQuery.data?.card.transactions ?? [],
    transactionsCount: getCardQuery.data?.card.transactionsCount ?? 0,
    hasCancelledTransactions:
      getCardQuery.data?.card.hasCancelledTransactions ?? false,
    isLinked: getCardQuery.data?.card.isLinked ?? false,
    isLinkedToViewer: getCardQuery.data?.card.isLinkedToViewer ?? false,
    designUrl: getCardQuery.data?.card.designUrl,
    attachments: getCardQuery.data?.card.attachments,
  };

  const videoAttachment = attachmentByType(attachments, 'VIDEO');
  const {
    isLinkedToSomeoneElse,
    canUploadVideoAttachment,
    hasVideoAttachment,
    isVideoTranscoded,
    videoExpiresInWeeks,
  } = {
    isLinkedToSomeoneElse: isLinked && !isLinkedToViewer,
    canUploadVideoAttachment: !isLinked && balance > 0,
    hasVideoAttachment:
      videoAttachment?.type === 'VIDEO' && videoAttachment?.videoUrl,
    isVideoTranscoded: Boolean(videoAttachment?.transcodedUrl),
    videoExpiresInWeeks: videoAttachment
      ? Math.round(
          (videoAttachment.expiresAt - nowMillis()) /
            times.VIDEO_EXPIRY_GRANULARITY,
        )
      : getCardQuery.data?.card.createdAt
      ? (getCardQuery.data?.card.createdAt + times.DEFAULT_VIDEO_EXPIRY) /
        times.VIDEO_EXPIRY_GRANULARITY
      : times.DEFAULT_VIDEO_EXPIRY / times.VIDEO_EXPIRY_GRANULARITY,
  };

  const vcardAttachment = attachmentByType(attachments, 'VCARD');
  const vcardUrl = vcardAttachment?.url ?? vcardAttachment?.videoUrl;

  const canLoadMoreTransactions = transactions.length < transactionsCount;

  const cardAssetStyle = {
    width: '100%',
    borderRadius: 16,
  };

  async function handleLinkToDeviceClick() {
    if (!deviceId) {
      const registerDeviceResponse = await registerDevice();

      if (!registerDeviceResponse?.data) {
        return;
      }

      const { _id: newDeviceId } =
        registerDeviceResponse.data.registerDevice.device;

      await activateDevice({
        variables: {
          input: {
            deviceId: newDeviceId,
          },
        },
      });
      pickDeviceId(newDeviceId);
    }

    await linkCard({
      variables: {
        input: {
          cardId,
        },
      },
    });
  }

  async function handleUnlinkFromDeviceClick() {
    await unlinkCard({
      variables: {
        input: {
          cardId,
        },
      },
    });
  }

  async function handleUploadSuccess(response: any) {
    const { name, thumbnail, transcoded } = response.data.data.file;

    const { type, videoUrl, thumbnailUrl, transcodedUrl } = {
      type: 'VIDEO',
      videoUrl: `${uploadsUrl}/api/file/${name}`,
      thumbnailUrl: `${uploadsUrl}/api/file/${thumbnail}`,
      transcodedUrl: transcoded
        ? `${uploadsUrl}/api/file/${transcoded}`
        : undefined,
    };

    await setCardAttachment({
      variables: {
        input: {
          cardId,
          type,
          videoUrl,
          thumbnailUrl,
          transcodedUrl,
        },
      },
    });
  }

  async function handleVideoDeletionConfirmation() {
    await deleteCardAttachment({
      variables: {
        input: {
          cardId,
        },
      },
    });
    videoDeletionDialog.onClose();
  }

  function handleLoadMoreTransactions() {
    getCardQuery.fetchMore({
      variables: {
        transactionsSkip: transactions.length,
        transactionsLimit: pagination.PAGE_SIZE,
      },
    });
  }

  function IsLinkedToSomeoneElse() {
    return (
      <Stack maxWidth="50rem" alignSelf="center" paddingX={{ base: 2, sm: 4 }}>
        <Heading size="2xl">
          {t('thisCardIs')}&nbsp;
          <UTag
            as="span"
            verticalAlign="baseline"
            minWidth={undefined}
            fontSize="100%"
            borderColor="red.500"
            backgroundColor="red.100"
          >
            {t('takenLower')}
          </UTag>
        </Heading>
        <Text fontSize="2xl">
          <Trans t={t} i18nKey="takenMeans">
            «<strong>Taken</strong>» means that this card has been linked to a
            different device. No information may be displayed or modified by
            anyone else. Please return this card to the owner, a{' '}
            <Link to="/shops">participating shop</Link>, or your local
            lost+found office.
          </Trans>
        </Text>
        <Stack direction="column">
          <Button
            as={Link}
            to="/shops"
            size="lg"
            colorScheme="brand"
            borderColor="brand"
            borderWidth={2}
          >
            {t('participating')}
          </Button>

          <Button
            as={Link}
            to="/"
            variant="outline"
            size="lg"
            colorScheme="brand"
            backgroundColor="white"
            borderWidth={2}
          >
            {t('learnMore')}
          </Button>
        </Stack>
      </Stack>
    );
  }

  function VcardPanel() {
    const { data, error } = useSWR(vcardUrl, (args) =>
      fetch(args).then((res) => res.text()),
    );
    if (error) {
      return (
        <VStack marginX="auto">
          <MdBrokenImage size={40} />
          <Text size="2xl">{t('missingData')}</Text>
        </VStack>
      );
    }
    if (!data) {
      return <CenteredSpinner />;
    }
    const vCardData = parseVCards(data);
    const vCard: VCard4 | undefined = vCardData.vCards?.[0];
    const vCardAdr = vCard?.ADR?.[0]?.value;

    return (
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 4, md: 8 }}
        marginX="auto"
      >
        <Image
          maxWidth="20em"
          margin="auto"
          alt={vCard?.FN[0].value ?? vcardAttachment?.description}
          src={vcardAttachment.thumbnailUrl}
          fallback={
            <Box maxWidth="10em" margin="auto">
              <IoPerson size="100%" color="gray" />
            </Box>
          }
        />
        <VStack align="left" spacing={-1} fontSize="2xl">
          <Text>{vcardAttachment.description ?? t('vcardHeading')}</Text>
          <Text>
            <ChakraLink href={vCard?.URL?.[0].value ?? 'https://trifence.ch'}>
              {vCard?.ORG?.[0].value}
            </ChakraLink>
          </Text>
          <Text>{vCardAdr?.streetAddress?.[0]}</Text>
          <Text>{`${vCardAdr?.postalCode?.[0]} ${vCardAdr?.locality?.[0]}`}</Text>
          <Text paddingTop={2}>
            <ChakraLink href={vCard?.TEL?.[0]?.value}>
              {vCard?.TEL?.[0]?.value?.substr(4)}
            </ChakraLink>
          </Text>
          <Text>
            <ChakraLink href={`mailto:${vCard?.EMAIL?.[0]?.value}`}>
              {vCard?.EMAIL?.[0]?.value}
            </ChakraLink>
          </Text>
          <HStack paddingTop={2}>
            <Text>Download:</Text>
            <ChakraLink href={vcardUrl}>
              <TiBusinessCard />
            </ChakraLink>
          </HStack>
        </VStack>
      </Stack>
    );
  }

  function CardDetails() {
    // Below md, use big icons, no text, and Info panel.
    // md and above, use text but no Info panel; scale text according to space.
    // Inherits `hideTabLabel` from enclosing function.
    const tabFontSize = useBreakpointValue({
      base: '2xl',
      md: 'sm',
      lg: 'lg',
      xl: '2xl',
    });
    useEffect(() => {
      if (vcardUrl && !vcardActivated) {
        setVcardActivated(true);
        setTabIndex(1);
      } else if (tabIndex === 0 && hideTabLabel === false) {
        setTabIndex(vcardUrl ? 1 : 2);
      }
    });

    const tabs: TabElement[] = [
      {
        label: 'Info', // Text will never be shown, we just need a key
        panel: <InfoPanel />,
        leftIcon: <FaInfoCircle size={20} />,
        small: true,
        hidden: !hideTabLabel,
      },
      {
        label: t('vcardHeading'),
        panel: <VcardPanel />,
        leftIcon: <TiBusinessCard size={20} />,
        hidden: !vcardUrl,
      },
      {
        label: t('shops'),
        panel: <Businesses noHelmet replacements={{ brand: 'CompanyON' }} />,
        leftIcon: <AiFillShop size={20} />,
      },
      {
        label: t('transactions'),
        panel: <TransactionsTabPanel />,
        leftIcon: <GrTransaction size={20} />,
      },
      {
        label: t('specialOffers'),
        panel: <SpecialOffersTabPanel />,
        leftIcon: <IoIosFlash size={20} />,
      },
      {
        label: t('video'),
        panel: <VideoTabPanel />,
        leftIcon: <IoIosVideocam size={20} />,
        hidden: !canUploadVideoAttachment,
      },
      {
        label: t('security'),
        panel: <SecurityTabPanel />,
        leftIcon: <IoIosLock size={20} />,
      },
    ];

    function InfoPanel() {
      return (
        <>
          <Heading as="h2">{t('views')}</Heading>
          <Text fontSize="2xl">{t('explainViews')}</Text>
          {tabs.slice(1).map((tab: TabElement, i: number) => (
            <Button
              key={tab.label}
              hidden={tab.hidden}
              width="full"
              borderColor="black"
              borderWidth={2}
              backgroundColor="white"
              leftIcon={tab.leftIcon}
              onClick={() => setTabIndex(i + 1)}
            >
              <Text align="left" width="80%">
                {tab.label}
                {tab.isNew ? (
                  <Badge marginLeft={2} colorScheme="orange">
                    {t('new')}
                  </Badge>
                ) : undefined}
              </Text>
            </Button>
          ))}
        </>
      );
    }

    return (
      <Tabs
        variant="soft-rounded"
        width="full"
        size="lg"
        isFitted={true}
        colorScheme="brand"
        index={tabIndex ?? (hideTabLabel === false ? 0 : 1)}
        onChange={setTabIndex}
      >
        <TabList marginX={4} fontFamily="Montserrat">
          {tabs.map((tab: TabElement) => (
            <IconTab
              key={tab.label}
              leftIcon={tab.leftIcon}
              shrink={hideTabLabel}
              hidden={tab.hidden}
              newBadge={tab.isNew ? t('new') : undefined}
              paddingX={0}
              fontSize={tabFontSize}
            >
              {tab.label}
            </IconTab>
          ))}
        </TabList>

        <TabPanels>
          {tabs.map((tab: TabElement) => (
            <TabPanel key={tab.label}>
              <VStack maxWidth={tab.small ? 'xl' : '5xl'} marginX="auto">
                <Stack
                  width="100%"
                  marginX={0}
                  paddingX={{ base: 0, md: 8 }}
                  paddingY={{ base: 2, md: 8 }}
                  paddingBottom={16}
                  spacing={tab.small ? 2 : 8}
                >
                  {tab.panel}
                </Stack>
              </VStack>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    );
  }

  function SpecialOffersTabPanel() {
    return <SpecialOffers />;
  }

  function TransactionsTabPanel() {
    if (!transactions || transactions.length === 0) {
      return (
        <VStack align="center">
          <IoBagHandle size={64} />
          <Text fontSize="2xl">{t('noTransactions')}</Text>
        </VStack>
      );
    }

    return (
      <>
        <Heading
          as="h1"
          color="brand.700"
          textAlign={{ base: 'center', md: 'left' }}
          size="2xl"
        >
          {t('transaction.title')}
        </Heading>

        <Text fontSize="2xl">
          <Text as="span">{t('transaction.start')}</Text>
          <MoneyTag
            as="span"
            verticalAlign="baseline"
            minWidth={undefined}
            fontSize="2xl"
            perspective="CARD"
            amount={+1}
            isCancelled={false}
          >
            {t('transaction.credit')}
          </MoneyTag>
          <Text as="span">{t('transaction.conjunction')}</Text>
          <MoneyTag
            as="span"
            verticalAlign="baseline"
            minWidth={undefined}
            fontSize="2xl"
            perspective="CARD"
            amount={-1}
            isCancelled={false}
          >
            {t('transaction.debit')}
          </MoneyTag>
          <Text as="span" marginRight={2}>
            {t('transaction.end')}
          </Text>
          {hasCancelledTransactions ? (
            <>
              {t('transactionCancelled.start')}
              <Text
                as="span"
                bgImage={cancelAsset}
                bgRepeat="repeat"
                paddingTop={2}
                paddingBottom={3}
              >
                <MoneyTag
                  as="span"
                  marginX={2}
                  verticalAlign="baseline"
                  minWidth={undefined}
                  fontSize="2xl"
                  perspective="CARD"
                  amount={-1}
                  isCancelled={true}
                >
                  {t('transactionCancelled.strikeThrough')}
                </MoneyTag>
              </Text>
              {t('transactionCancelled.end')}
            </>
          ) : undefined}
        </Text>
        <TransactionList
          perspective="CARD"
          transactions={transactions}
          locale={i18n.language}
          canLoadMore={canLoadMoreTransactions}
          onLoadMore={handleLoadMoreTransactions}
          placeholderAsset={placeholderAsset}
          cancelAsset={cancelAsset}
        />

        {canLoadMoreTransactions ? null : (
          <BackToTop alignSelf="center">{t('backToTop')}</BackToTop>
        )}
      </>
    );
  }

  function SecurityTabPanel() {
    const linkCardButton = {
      children: isLinkedToViewer ? t('unlinkFromDevice') : t('linkToDevice'),
      leftIcon: isLinkedToViewer ? (
        <BiUnlink size={20} />
      ) : (
        <BiLink size={20} />
      ),
      isLoading:
        registerDeviceMutation.loading ||
        activateDeviceMutation.loading ||
        linkCardMutation.loading ||
        unlinkCardMutation.loading,
      loadingText: isLinkedToViewer
        ? t('unlinkingFromDevice')
        : t('linkingToDevice'),
      onClick: isLinkedToViewer
        ? handleUnlinkFromDeviceClick
        : handleLinkToDeviceClick,
    };

    const buttons = [linkCardButton];

    return (
      <>
        <Heading as="h2" textAlign="left" size="2xl">
          {t('securityTitle')}
        </Heading>
        <Skeleton isLoaded={!getCardQuery.loading} borderRadius="md">
          <Text as="span" fontSize="2xl" paddingY={2} paddingRight={2}>
            {t('thisCardIs')}
          </Text>
          <UTag
            as="span"
            verticalAlign="baseline"
            minWidth={undefined}
            fontSize="2xl"
            borderColor={
              isLinkedToViewer
                ? 'brand.500'
                : isLinkedToSomeoneElse
                ? 'red.500'
                : 'green.600'
            }
            backgroundColor={
              isLinkedToViewer
                ? 'brand.100'
                : isLinkedToSomeoneElse
                ? 'red.100'
                : 'green.200'
            }
          >
            {isLinkedToViewer
              ? t('linkedLower')
              : isLinkedToSomeoneElse
              ? t('takenLower')
              : t('unlinkedLower')}
            .
          </UTag>
          <Text as="span" fontSize="2xl" paddingY={2} paddingLeft={2}>
            {t('describeLinking')}
          </Text>
          <Text as="span" fontSize="2xl" paddingY={2} paddingLeft={2}>
            {t('describeLinkingVideo')}
          </Text>
        </Skeleton>
        <ButtonList items={buttons} />
      </>
    );
  }

  function VideoTabPanel() {
    const uploadVideoButton = {
      children: t('uploadVideo'),
      leftIcon: <IoCloudUploadOutline size={20} />,
      onClick: videoUploadModal.onOpen,
    };

    const setVideoExpiryButton = {
      children: t('setVideoExpiry'),
      leftIcon: <IoStopwatchOutline size={20} />,
      onClick: videoExpiryDialog.onOpen,
    };

    const deleteVideoButton = {
      children: t('deleteVideo'),
      leftIcon: <IoTrashOutline size={20} />,
      onClick: videoDeletionDialog.onOpen,
    };

    const buttons = hasVideoAttachment
      ? [uploadVideoButton, setVideoExpiryButton, deleteVideoButton]
      : [uploadVideoButton];

    return (
      <>
        <Heading as="h2" textAlign="left" size="2xl">
          {t('videoTitle')}
        </Heading>

        <Text fontSize="2xl">{t('explainVideo')}</Text>
        <ButtonList items={buttons} />
      </>
    );
  }

  function VideoExpiryDialog(props: ExpiryProps) {
    // useState() cannot depend on a dynamically computed value *in the same function*,
    // so we need to put this into its own component.
    const { expiresInWeeks } = props;
    const [weeks, setWeeks] = useState(expiresInWeeks);

    async function handleExpiryChange() {
      let newAttachment = {
        ...videoAttachment,
        expiresAt: nowMillis() + weeks * times.VIDEO_EXPIRY_GRANULARITY,
      };
      delete newAttachment._id;
      delete newAttachment.__typename;
      await setCardAttachment({
        variables: {
          input: newAttachment,
        },
      });
      videoExpiryDialog.onClose();
    }

    return (
      <Alerter
        headerText={t('videoExpiryDialog.title')}
        cancelText={t('videoExpiryDialog.cancel')}
        confirmationText={t('videoExpiryDialog.confirm')}
        confirmationColorScheme="green"
        onConfirmation={handleExpiryChange}
        disclosure={videoExpiryDialog}
        performingOperation={setCardAttachmentMutation.loading}
      >
        <Stack>
          <Text>{t('videoExpiryDialog.expiration', { count: weeks })}</Text>
          <Slider
            aria-label="Duration"
            value={weeks}
            min={times.MINIMUM_VIDEO_EXPIRY / times.VIDEO_EXPIRY_GRANULARITY}
            max={times.MAXIMUM_VIDEO_EXPIRY / times.VIDEO_EXPIRY_GRANULARITY}
            onChange={(e: number) => {
              setWeeks(e);
            }}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Stack>
      </Alerter>
    );
  }

  return (
    <Stack
      marginBottom={16}
      paddingX={{ base: 0, md: 8 }}
      paddingY={{ base: 2, md: 8 }}
      spacing={8}
    >
      <VStack spacing={4}>
        <Helmet>
          <title>CompanyON: {t('yourCard')}</title>
        </Helmet>
        <Heading as="h1" color="brand.700" textAlign="center" size="2xl">
          {vcardUrl
            ? balance
              ? t('dualHeading')
              : t('vcardHeading')
            : t('heading')}
        </Heading>

        <Skeleton isLoaded={!getCardQuery.loading} borderRadius="md">
          {isLinkedToSomeoneElse ? (
            <VStack>
              <UTag
                minWidth={undefined}
                fontSize="4xl"
                fontWeight="bold"
                borderColor="red.500"
                backgroundColor="red.100"
                animation={flyInAnimation}
              >
                {t('taken')}
              </UTag>
              {vcardUrl ? (
                <UTag
                  minWidth={undefined}
                  fontSize="4xl"
                  fontWeight="bold"
                  borderColor="blue.500"
                  backgroundColor="blue.100"
                  animation={flyInAnimation2}
                >
                  {vcardAttachment?.description ?? t('vcardHeading')}
                  <ChakraLink paddingLeft={2} href={vcardUrl}>
                    <AiOutlineDownload />
                  </ChakraLink>
                </UTag>
              ) : undefined}
            </VStack>
          ) : (
            <VStack>
              <FlyInMoneyTag
                fontSize="3xl"
                fontWeight="bold"
                color="black"
                amount={balance}
                perspective="CARD"
                currency={t('value') + ' CHF'}
              />
              {vcardUrl ? (
                <UTag
                  fontSize="3xl"
                  fontWeight="bold"
                  minWidth="7em"
                  padding={2}
                  borderColor="blue.500"
                  backgroundColor="blue.100"
                  animation={flyInAnimation2}
                >
                  {vcardAttachment?.description ?? t('vcardHeading')}
                  <ChakraLink paddingLeft={2} href={vcardUrl}>
                    <AiOutlineDownload />
                  </ChakraLink>
                </UTag>
              ) : undefined}
            </VStack>
          )}
        </Skeleton>
      </VStack>

      {getCardQuery.loading ? null : (
        <Stack spacing={8}>
          <Box
            width={{
              base: '80%',
              sm: '60%',
              md: '45%',
              lg: '40%',
              xl: '30%',
            }}
            alignSelf="center"
          >
            {hasVideoAttachment && !isLinkedToSomeoneElse ? (
              <video
                style={cardAssetStyle}
                controls={true}
                poster={videoAttachment.thumbnailUrl}
                preload="metadata"
                key={videoAttachment.videoUrl}
              >
                {isVideoTranscoded ? (
                  <source src={videoAttachment.transcodedUrl} />
                ) : null}

                <source src={videoAttachment.videoUrl} />
                <AnimatedCard style={cardAssetStyle} src={designUrl} />
              </video>
            ) : (
              <AnimatedCard style={cardAssetStyle} src={designUrl} />
            )}
          </Box>

          {isLinkedToSomeoneElse ? <IsLinkedToSomeoneElse /> : <CardDetails />}
        </Stack>
      )}

      {videoUploadModal.isOpen ? (
        <VideoUploadModal
          allowedFileTypes={['video/mp4', 'video/webm', 'video/quicktime']}
          upload={{
            url: `${uploadsUrl}/api/video`,
            fieldName: 'file',
            headers: {
              Authorization: `Bearer cardId/${cardId}`,
            },
          }}
          locale="en"
          translations={{
            /* FIXME: Procure translations in a cleaner way */
            en: {
              heading: {
                IDLE: t('videoUploadModal.heading.IDLE'),
                UPLOADING: t('videoUploadModal.heading.UPLOADING'),
                UPLOAD_SUCCESS: t('videoUploadModal.heading.UPLOAD_SUCCESS'),
                UPLOAD_FAILURE: t('videoUploadModal.heading.UPLOAD_FAILURE'),
              },
              subheading: {
                IDLE: t('videoUploadModal.subheading.IDLE'),
                UPLOADING: t('videoUploadModal.subheading.UPLOADING'),
                UPLOAD_SUCCESS: t('videoUploadModal.subheading.UPLOAD_SUCCESS'),
                UPLOAD_FAILURE: t('videoUploadModal.subheading.UPLOAD_FAILURE'),
              },
            },
            de: {
              heading: {
                IDLE: t('videoUploadModal.heading.IDLE'),
                UPLOADING: t('videoUploadModal.heading.UPLOADING'),
                UPLOAD_SUCCESS: t('videoUploadModal.heading.UPLOAD_SUCCESS'),
                UPLOAD_FAILURE: t('videoUploadModal.heading.UPLOAD_FAILURE'),
              },
              subheading: {
                IDLE: t('videoUploadModal.subheading.IDLE'),
                UPLOADING: t('videoUploadModal.subheading.UPLOADING'),
                UPLOAD_SUCCESS: t('videoUploadModal.subheading.UPLOAD_SUCCESS'),
                UPLOAD_FAILURE: t('videoUploadModal.subheading.UPLOAD_FAILURE'),
              },
            },
          }}
          onClose={videoUploadModal.onClose}
          onUploadSuccess={handleUploadSuccess}
        />
      ) : null}

      <Alerter
        headerText={t('videoDeletionDialog.title')}
        cancelText={t('videoDeletionDialog.cancel')}
        confirmationText={t('videoDeletionDialog.confirm')}
        confirmationColorScheme="red"
        onConfirmation={handleVideoDeletionConfirmation}
        disclosure={videoDeletionDialog}
        performingOperation={deleteCardAttachmentMutation.loading}
      >
        {t('videoDeletionDialog.confirmation')}
      </Alerter>

      <VideoExpiryDialog expiresInWeeks={videoExpiresInWeeks} />
    </Stack>
  );
}
