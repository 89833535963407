import { useSubscription } from '@apollo/client';
import { useQuery, QueryHookOptions } from '@companyon/graphql';
import { GET_CARD_QUERY } from '../../schema/queries/getCard';
import { CARD_DATA_CHANGED_SUBSCRIPTION } from '../../schema/subscriptions/cardDataChanged';

export function useGetCardQuery(options?: QueryHookOptions) {
  const query = useQuery(GET_CARD_QUERY, options);

  useSubscription(CARD_DATA_CHANGED_SUBSCRIPTION, {
    variables: options?.variables,
    skip: options?.skip,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const previousQueryResult = client.readQuery({
        query: GET_CARD_QUERY,
        variables: options?.variables,
      });

      client.writeQuery({
        query: GET_CARD_QUERY,
        variables: options?.variables,
        data: {
          ...previousQueryResult,
          ...subscriptionData.data.cardDataChanged,
        },
      });
    },
  });

  return query;
}
